import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router, RouterLink } from '@angular/router';
import { AppConstants } from "../app.constants";
import { User } from '../model/user';
import { CacheService } from './cache.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    redirectUrl = '';
    isMobileWebViewSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private http: HttpClient,
        private router: Router,
        private cacheService: CacheService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }



    login(username: string, password: string) {
        sessionStorage.clear();
        const body = new HttpParams()
            .set('username', username)
            .set('password', password)
            .set('platform', 'website');
        return this.http.post<any>(environment.DEV_DOMAIN_URL + 'account/login/', body.toString(), { headers: AppConstants.AUTH_HEADERS })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user.username && user.access_token) {
                    sessionStorage.setItem('currentLoginTime', JSON.stringify((new Date).getTime()))
                    sessionStorage.setItem('loggedInUserInfo', JSON.stringify(user));
                    sessionStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }


    resetPassword(key: string, password: string, confirm_password: string) {
        // const body = new HttpParams()
        //     .set('key', key)
        //     .set('password', password)
        //     .set('confirm_password', confirm_password);
        const body = { key: key, password: password, confirm_password: confirm_password };

        return this.http.post<any>(environment.DEV_DOMAIN_URL + 'account/reset_password/', body)
            .pipe(map(user => {
                return user;
            }));
    }

    setPassword(key: string, password: string, confirm_password: string) {
        // const body = new HttpParams()
        //     .set('key', key)
        //     .set('password', password)
        //     .set('confirm_password', confirm_password);
        const body = {
            key: key,
            password: password,
            confirm_password: confirm_password
        }
        // return this.http.post<any>(environment.DEV_DOMAIN_URL+'account/reset_password/', body, {headers:AppConstants.AUTH_HEADERS})
        return this.http.post<any>(environment.DEV_DOMAIN_URL + 'account/reset_password/', body)
            .pipe(map(user => {
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        const user = sessionStorage.getItem('currentUser');
        if (!user) return;
        this.http.post<any>(environment.DEV_DOMAIN_URL + 'account/logout/', null).subscribe((resp) => {
            this.clearUserDetails();
            this.cacheService.clear();
        }, (err) => this.clearUserDetails());
    }

    clearUserDetails() {
        sessionStorage.removeItem('loggedInUserInfo');
        sessionStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.router.navigate(['/']);
    }

    login_refresh(username: string, refresh: string): Observable<any> {
        sessionStorage.clear();
        const body = new HttpParams()
            .set('username', username)
            .set('refresh', refresh)
            .set('platform', 'website');
        return this.http.post<any>(environment.DEV_DOMAIN_URL + 'account/login/refresh/', body.toString(), { headers: AppConstants.AUTH_HEADERS })
            .pipe(map(user => {
                if (user.username && user.access_token) {
                    sessionStorage.setItem('currentLoginTime', JSON.stringify((new Date).getTime()))
                    sessionStorage.setItem('loggedInUserInfo', JSON.stringify(user));
                    sessionStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }

    changePassword(changePwdModel): Observable<any> {
        return this.http.post<any>(environment.DEV_DOMAIN_URL + 'account/change_password/', changePwdModel);
    }

    getUserProfileDetails(): Observable<any> {
        return this.http.get<any>(environment.DEV_DOMAIN_URL + 'account/user_profile/');
    }

    updateUserProfile(userProfile: any): Observable<any> {
        return this.http.post<any>(environment.DEV_DOMAIN_URL + 'account/user_profile/', userProfile);
    }

    setCurrentUserDetails(profile) {
        this.currentUserSubject.next(profile);
    }

    validateUser(token: any): Observable<any> {
        return this.http.get<any>(environment.DEV_DOMAIN_URL + 'account/validate_user/');
    }

    getUserSubscriptionModules() {
        let subscription_modules = JSON.parse(sessionStorage.getItem('currentUser'))?.subscription_modules;
        return subscription_modules && subscription_modules instanceof Array ? subscription_modules : [];
    }

    async getUserPermissions() {
        const current_user = JSON.parse(sessionStorage.getItem('currentUser'));
        let role_permissions = JSON.parse(sessionStorage.getItem('currentUser')).role_permissions;
        return role_permissions && role_permissions instanceof Array ? role_permissions : []
        // if (current_user?.user_type == 'My Win User') {
        //     let permissions = [];
        //     let modules = await this.http.get<any>(environment.DEV_DOMAIN_URL + 'role_module_list/').toPromise();
        //     modules.forEach(x => {
        //         x.features.forEach(f => {
        //             const obj = {
        //                 feature_name: f.name, view: true, create: true, edit: true, delete: true, approve: true
        //             };
        //             permissions.push(obj);
        //         });
        //     });
        //     return permissions;
        // } else {
        //     let role_permissions = JSON.parse(sessionStorage.getItem('currentUser')).role_permissions;
        //     return role_permissions && role_permissions instanceof Array ? role_permissions : []
        // }
    }

    getResellerFunctionsByOEMOrgId(id): Array<number> {
        const current_user = JSON.parse(sessionStorage.getItem('currentUser'));
        const reseller_functions = current_user?.reseller_function_assign ? current_user?.reseller_function_assign : [];
        const oem_reseller_function_ids = reseller_functions.find(x => x.oem_org == id)?.function_ids;
        return oem_reseller_function_ids ? oem_reseller_function_ids : [];
    }

    goToUserHomePage() {
        const current_user = JSON.parse(sessionStorage.getItem('currentUser'));
        const user_type = current_user.user_type;
        if (user_type == "Admin") {
            this.router.navigate(['/admin']);
        } else if (user_type == "OEM" || user_type == "Reseller") {
            this.router.navigate(['/oem']);
        } else if (user_type == "Food Manufacturer") {
            this.router.navigate(['/customer']);
        } else if (user_type == "Individual") {
            this.router.navigate(['/individual']);
        } else if (user_type == "My Win User") {
            this.router.navigate(['/mywin-user']);
        }
    }

    getIsMobileWebView(): Observable<boolean> {
        return this.isMobileWebViewSubject.asObservable();
    }

    setIsMobileWebView(toggle: boolean) {
        this.isMobileWebViewSubject.next(toggle);
    }
}
