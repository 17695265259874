import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { User } from 'src/app/model/user';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/service/alert.service';
import { Business } from 'src/app/service/Business.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EwImgCropperComponent } from '../components/ew-img-cropper/ew-img-cropper.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  @Output() cancel = new EventEmitter();
  bsModalRef: BsModalRef;
  currentUser: User;
  profileImageSrc = '/assets/img/sampleImage.png';
  companyLogoImageSrc = '/assets/img/sampleImage.png';
  isProfile = true;
  chagenPwdModel = { current_password: '', new_password: '', confirm_password: '' };
  ferror = { current_password: false, new_password: false, confirm_password: false, isPwdNotMatch: false };
  pfError = {
    name: false, company_name: false, country: false, contact_number: false,
    location: false, designation: false
  };
  showChangePwd = false;
  userProfile = null;
  countries = [];
  selectedCountry = '';
  selctedPhoneCode = '';
  ewImgCropperComponent: EwImgCropperComponent;

  scrWidth: number;
  mobile_view = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private alertService: AlertService,
    private businessService: Business,
    private modalService: BsModalService,
    private _location: Location
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    let _user: any = this.currentUser;

    this.getScreenSize();
  }

  ngOnInit(): void {
    this.getCountries();
  }

  getCountries() {
    this.businessService.getAllCountries().subscribe((data) => {
      this.countries = data;
      this.getUserProfileDetails();
    });
  }

  getUserProfileDetails(redirect = false) {
    this.authenticationService.getUserProfileDetails().subscribe((data) => {
      this.userProfile = data;
      const phoneParts = this.userProfile.contact_number?.split(' ');
      if (phoneParts && phoneParts.length === 2) {
        this.selctedPhoneCode = phoneParts[0];
        this.userProfile.contact_number = phoneParts[1];
        if (this.selctedPhoneCode) {
          const country = this.countries.find((item) => item.phone_code == this.selctedPhoneCode);
          this.selectedCountry = country.alpha3;
        }
      } else {
        this.onCountryChange();
      }
      this.authenticationService.setCurrentUserDetails(this.userProfile);
      if (redirect) {
        if (this.userProfile.user_type == 'Admin') {
          this.router.navigate(['/admin']);
        }
        if (this.userProfile.user_type == 'OEM') {
          this.router.navigate(['/oem']);
        }
        if (this.userProfile.user_type == 'Food Manufacturer') {
          this.router.navigate(['/customer']);
        }
        if (this.userProfile.user_type == 'My Win User') {
          this.cancel.emit();
        }
      }
    });
  }

  onSaveDetails() {
    const profile: any = {
      name: this.userProfile.name,
      // first_name: this.userProfile.first_name,
      // last_name: this.userProfile.last_name,
      company_name: this.userProfile.company_name,
      contact_number: this.selctedPhoneCode + ' ' + this.userProfile.contact_number,
      user_logo: this.userProfile.user_logo,
      company_logo: this.userProfile.company_logo,
    }
    // if (this.userProfile.user_type == 'end_user') {
    profile.location = this.userProfile.location;
    profile.designation = this.userProfile.designation;
    // }
    if (!this.validateProfile()) {
      return;
    }
    this.authenticationService.updateUserProfile(profile).subscribe((resp) => {
      this.alertService.success(resp.message, true);
      this.getUserProfileDetails();
    }, (err) => {
      this.alertService.error(err || err.message || 'failed to update');
    });
  }

  validateProfile(): boolean {
    this.pfError = {
      name: false, company_name: false, country: false,
      contact_number: false, location: false, designation: false
    };
    if (!this.userProfile.name) {
      this.pfError.name = true;
    }
    // if (!this.userProfile.first_name) {
    //   this.pfError.first_name = true;
    // }
    // if (!this.userProfile.last_name) {
    //   this.pfError.last_name = true;
    // }
    if (!this.userProfile.company_name) {
      this.pfError.company_name = true;
    }
    if (!this.userProfile.location) {
      this.pfError.country = true;
    }
    this.validatePhoneNumber();
    if (this.userProfile.user_type == 'Food Manufacturer' && !this.userProfile.location) {
      this.pfError.location = true;
    }
    if (this.userProfile.user_type == 'Food Manufacturer' && !this.userProfile.designation) {
      this.pfError.designation = true;
    }

    const eKeys = Object.keys(this.pfError);
    const isValid = eKeys.every((ek) => this.pfError[ek] == false);
    return isValid;
  }

  validatePhoneNumber(): boolean {
    const phonePattern = new RegExp('^[0-9]{6,10}$');
    const valid = phonePattern.test(this.userProfile.contact_number);
    this.pfError.contact_number = !valid;
    return valid;
  }

  onCancel() {
    // this.getUserProfileDetails(true);
    this._location.back();
  }

  onShowChangePwd() {
    this.showChangePwd = true;
  }

  onCancelChangePassword() {
    this.showChangePwd = false;
    this.chagenPwdModel = { current_password: '', new_password: '', confirm_password: '' };
    this.ferror = { current_password: false, new_password: false, confirm_password: false, isPwdNotMatch: false };
  }

  onChangePassword() {
    if (!this.validate()) {
      return;
    }
    this.authenticationService.changePassword(this.chagenPwdModel).subscribe((resp) => {
      this.alertService.success(resp.message);
      setTimeout(() => {
        this.router.navigate(['/login']);
      }, 2000);
    }, (err) => {
      this.alertService.error(err)
    });
  }

  validate() {
    this.ferror = { current_password: false, new_password: false, confirm_password: false, isPwdNotMatch: false };
    if (!this.chagenPwdModel.current_password) {
      this.ferror.current_password = true;
    }
    if (!this.chagenPwdModel.new_password) {
      this.ferror.new_password = true;
    }
    if (!this.chagenPwdModel.confirm_password) {
      this.ferror.confirm_password = true;
    } else if (this.chagenPwdModel.new_password !== this.chagenPwdModel.confirm_password) {
      this.ferror.isPwdNotMatch = true;
    }

    const eKeys = Object.keys(this.ferror);
    const isValid = eKeys.every((ek) => this.ferror[ek] == false);
    return isValid;
  }

  onCountryChange(isEndUser = false) {
    this.selctedPhoneCode = '';
    const country: any = this.countries.find((item: any) => item.alpha3 == this.userProfile.location);
    if (country && country.phone_code) {
      this.selctedPhoneCode = country.phone_code;
    }
  }

  onFileChanged(e, isProfile = true) {
    // const initialState = {
    //   imageChangedEvent: e
    // }
    // this.bsModalRef = this.modalService.show(EwImgCropperComponent, { initialState, class: 'modal-lg' });
    // this.bsModalRef.content.onClose.subscribe((resp) => {
    //   if(isProfile) {
    //     this.userProfile.user_logo = resp;
    //   } else {
    //     this.userProfile.company_logo = resp;
    //   }
    // });
    this.isProfile = isProfile;
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    // if (file.size > (1024 * 1024)) {
    //   this.ferror.filesize = true;
    // }
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    if (this.isProfile) {
      this.userProfile.user_logo = 'data:image/png;base64' + reader.result;
      this.onSaveDetails();
    } else {
      this.userProfile.company_logo = 'data:image/png;base64' + reader.result;
    }
    this.isProfile = true;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth > 768) {
      this.mobile_view = false;
    } else {
      this.mobile_view = true;
    }
  }

}
