<div class="row no-gutters page-head sticky-top bg-white">
    <ng-container *ngIf="equipment_health_rating">
        <div class="col-md-12">
            <div class="position-relative d-flex">
                <img src="/assets/icons/back_arrow.svg" alt="back arrow" (click)="onCancel()" class="hand mr-3">
                <span class="head-title">
                    {{equipment_name}}
                </span>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!equipment_health_rating">
        <div class="col-md-10">
            <div class="position-relative d-flex">
                <img src="/assets/icons/back_arrow.svg" alt="back arrow" (click)="onCancel()" class="hand mr-3">
                <span>
                    <h4 class="head-title">
                        {{equipment_name}} - {{checklist_entry?.checklist_name}} -
                        {{checklist_entry?.checklist_type_name}}
                    </h4>
                    <span>{{checklist_entry?.checklist_description}}</span>
                </span>
            </div>
        </div>
        <div class="col-md-2 text-right">
            <ng-container *ngIf="can_fill">
                <button *ngIf="isView" class="btn btn-outline-primary mr-2" (click)="onFillChecklist()">Fill
                    out</button>
            </ng-container>
            <ng-container>
                <button *ngIf="dynamic_data_perm?.edit" class="btn btn-outline-secondary" data-toggle="dropdown"
                    id="editChecklistOptions">
                    <span class="fa fa-ellipsis-v mx-1"></span>
                </button>
                <div class="dropdown-menu ew-more-options-box" aria-labelledby="editChecklistOptions">
                    <a *ngIf="dynamic_data_perm?.edit" (click)="onEditChecklist()" class="dropdown-item"><span
                            class="fa fa-edit mr-2"></span>
                        Edit Checklist
                    </a>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>

<div class="container-fluid layout-padding">
    <div class="lay-mobile">
        <div class="sync-alert" *ngIf="show_changes_saved_msg">
            <span class="fa fa-check-circle-o mr-1"></span>
            Changes saved
        </div>
        <ng-container *ngIf="task_box">

            <p-steps [model]="checklist_entry.field_entries" [(activeIndex)]="activeIndexs" [readonly]="false"
                (activeIndexChange)="stepperChanged($event)"></p-steps>

            <ng-container *ngFor="let field of checklist_entry.field_entries; let i = index;">

                <div class="card" *ngIf="current_field_no == i + 1">
                    <div class="card-header row" style="line-height: 40px;">
                        <div class="col-md-6 pl-4">
                            <div class="sub-head-18 text-white">Task {{current_field_no}} of
                                {{checklist_entry.field_entries.length}}</div>
                        </div>
                        <div class="col-md-6 text-right pr-4">
                            <button *ngIf="checklist_entry.field_entries.length != current_field_no" type="button"
                                class="btn btn-outline-primary-txticon"
                                (click)="onNextField('right')">Skip&nbsp;&nbsp;<span
                                    class="fa fa-angle-right"></span></button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="sub-title">Instructions</div>
                                <div class="sub-body mt-2" style="height: 104px;overflow: auto;">
                                    {{field.oem_instructions}}</div>
                            </div>
                            <div class="col-md-4">
                                <div class="sub-head-title">Reference File</div>
                                <div class="border bor-rad hei-doc mt-2" style="height: 104px !important;overflow: auto;white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;">
                                    <div *ngIf="field.reference_files.length === 0"
                                        class="sub-title2 p-3 hei-doc light-scroll-bar d-flex align-items-center justify-content-center"
                                        style="height: 104px !important;overflow: auto;">
                                        There are no files uploaded
                                    </div>
                                    <div *ngFor="let file of field.reference_files" class="bor-col d-flex"
                                        style="border-bottom: 1px solid rgba(145, 158, 171, 0.32);padding: 6px;">

                                        <div class="align-self-center mr-2" (click)="onDocumentClick(file)">
                                            <span><img width="32" height="32"
                                                    [src]="helper.getExtensionType(file.file_name)" type="image/svg+xml"
                                                    class="hand svg-white"></span>
                                        </div>
                                        <div class="flex-grow-1 width-size">
                                            <a class="sub-title2 hand"
                                                (click)="onDocumentClick(file)">{{file.file_name}}</a>
                                            <div class="text-light1">{{file.document_size+'kb'}}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="mt-4 mb-4" style="border-bottom: 1px solid rgba(145, 158, 171, 0.24);"></div>
                        <div class="mt-2">

                            <div class="form-group w-50">
                                <div *ngIf="field.input_type == 2 || field.input_type == 1">
                                    <label class="sub-title">{{field.field_name}}
                                        <span *ngIf="field.uom" class="ml-2">({{field.uom}}) </span>
                                        <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="field.input_value" #input_value
                                        (blur)="autoSave(input_value)" [class.input-error]="field.ferror.input_value"
                                        [readOnly]="isView || field.complete_status"
                                        (input)="checkLength(field.input_value)">
                                    <div *ngIf="field.ferror.input_value" class="text-danger fs-085">
                                        {{field.field_name}} value is required
                                    </div>
                                </div>
                                <div *ngIf="field.input_type == 3">
                                    <label class="sub-title">{{field.field_name}} <span
                                            class="text-danger">*</span></label>
                                    <input *ngIf="field.complete_status" type="text" class="form-control"
                                        [(ngModel)]="field.input_value" readOnly>
                                    <app-ew-select *ngIf="!field.complete_status" [options]="field.list_options"
                                        [(ngModel)]="field.input_value" optionText="type" optionValue="type"
                                        [class.input-error]="field.ferror.input_value" [read_only]="isView">
                                    </app-ew-select>
                                    <div *ngIf="field.ferror.input_value" class="text-danger fs-085">
                                        {{field.field_name}} value is required
                                    </div>
                                </div>
                                <div *ngIf="field.input_type == 4">
                                    <label class="sub-title">{{field.field_name}} <span
                                            class="text-danger">*</span></label>
                                    <input *ngIf="field.complete_status" type="text" class="form-control"
                                        [ngModel]="field.input_value |date:'dd-MM-yyyy'" readOnly>
                                    <app-ew-datepicker *ngIf="!field.complete_status" [ngModel]="field.input_value"
                                        (ngModelChange)="field.input_value = $event; autoSave()">
                                    </app-ew-datepicker>
                                    <div *ngIf="field.ferror.input_value" class="text-danger fs-085">
                                        {{field.field_name}} value is required
                                    </div>
                                </div>
                                <div *ngIf="field.input_type == 5">
                                    <div>
                                        <label class="sub-title">{{field.field_name}} <span
                                                class="text-danger">*</span></label>
                                    </div>
                                    <div class="row no-gutters">
                                        <div class="form-check form-check-inline" style="height: 40px !important;">
                                            <input type="radio" class="form-check-input" name="optioncheck" value='Yes'
                                                [(ngModel)]="field.input_value" (change)="autoSave()"
                                                [disabled]="field.complete_status">
                                            <label class="form-check-label">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline" style="height: 40px !important;">
                                            <input type="radio" class="form-check-input" name="optioncheck" value='No'
                                                [(ngModel)]="field.input_value" (change)="autoSave()"
                                                [disabled]="field.complete_status">
                                            <label class="form-check-label">No</label>
                                        </div>

                                    </div>
                                    <div *ngIf="field.ferror.input_value" class="text-danger fs-085">
                                        {{field.field_name}} value is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-8" [class.cursor]="isView || field.complete_status">
                                <div class="sub-title">Upload files / images</div>
                                <div class="my-2 bg-light rounded p-2 position-relative">
                                    <div style="border: 2px dashed lightgray;min-height: 200px;max-height: 290px;overflow:auto"
                                        class="rounded d-flex flex-column justify-content-center text-secondary hand">
                                        <input *ngIf="upload_mode" type="file" id="sol-doc-input" multiple
                                            class="hidden-file" (change)="onDocumentChange($event.target.files)">
                                        <div *ngFor="let file of uploaded_files"
                                            class="bg-white py-1 px-3 border-bottom">
                                            <div class="d-flex">
                                                <div class="mr-2">
                                                    <span><img width="32" height="32"
                                                            [src]="helper.getExtensionType(file.name)"
                                                            class="hand svg-white" /></span>
                                                </div>
                                                <div class="flex-grow-1 text-truncate">
                                                    <strong>{{file.name}}</strong>
                                                    <div class="text-secondary fs-085">{{getFileSize(file.size)}}
                                                    </div>
                                                </div>
                                                <div>
                                                    <span class="fa fa-times mr-1 hand text-secondary"
                                                        (click)="onRemoveUploadedFile(file, $event)"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="upload_mode" class="text-center">
                                            <span class="fa fa-file mb-2"></span>
                                            <div>Drag & Drop a file</div>
                                            <div>or click to upload the document</div>
                                        </div>
                                    </div>

                                </div>
                                <div class="text-right mb-2">
                                    <button class="btn btn-outline-secondary" (click)="onDocumentAdd(field)">
                                        Add Document
                                    </button>
                                </div>
                                <div *ngIf="uploaded_files_error.length > 0" class="text-danger my-2">
                                    <ng-container *ngFor="let err of uploaded_files_error">
                                        <p *ngIf="err.size_limit">{{err.name}} exceeds maximum size limit of 1MB</p>
                                        <p *ngIf="err.type">{{err.name}} is not a valid file</p>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="sub-head-title">Upload List</div>
                                <div class="border bor-rad hei-doc mt-2" style="overflow: auto !important;">
                                    <div *ngIf="field.fm_file_urls.length === 0"
                                        class="sub-title2 p-3 d-flex align-items-center justify-content-center">
                                        There are no files uploaded
                                    </div>
                                    <div *ngFor="let file of field.fm_file_urls" class="bor-col p-2 d-flex"
                                        style="border-bottom: 1px solid rgba(145, 158, 171, 0.32);">
                                        <div class="align-self-center mr-2">
                                            <span><img width="32" height="32" [src]="helper.getExtensionType(file.name)"
                                                    type="image/svg+xml" class="hand svg-white"></span>
                                        </div>
                                        <div class="flex-grow-1 width-size">
                                            <a class="sub-doc hand" (click)="onDocumentClick(file)">{{file.name}}</a>
                                            <div class="text-light1">{{file.size}}&nbsp;kb</div>
                                        </div>
                                        <div class="">
                                            <span *ngIf="!(isView || field.complete_status)"
                                                class="fa fa-trash mr-1 hand" (click)="removeFile(file, field)"></span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="w-100 text-right checklist">
                            <!-- [disabled]="!field.complete_status" -->
                            <button type="button" [disabled]="isView || field.complete_status"
                                class="btn btn-primary-txticon mt-4 w-120 float-right"
                                (click)="onFieldCompleteChange(field)"><span>Save</span>&nbsp;&nbsp;<span
                                    class="fa fa-angle-right align-end"></span></button>
                            <div *ngIf="field.ferror.complete_status" class="text-danger fs-085 text-center">
                                Please complete pending tasks
                            </div>
                        </div>
                    </div>


                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="finish_box">
            <div class="card">
                <div class="card-header row" style="line-height: 40px;">
                    <div class="col-md-12">
                        <div class="sub-head-18 text-white float-left"><span class="fa fa-angle-left mr-3"
                                style="font-size: 24px;vertical-align: middle;"></span>Final
                            Step
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 pl-4">
                            <div class="form-group">
                                <div>
                                    <label class="sub-title">How would you rate the equipment health? <span
                                            class="text-danger">*</span></label>
                                </div>
                                <div class="row border rating-options">
                                    <div class="col-md-4 d-flex align-items-center bgradio1 txt-radio1 border-right"
                                        (click)="setMntChecklistEquipmentRating('1')">
                                        <img *ngIf="checklist_entry.equipment_rating != '1'"
                                            src="/assets/icons/ic_radio_off.svg" alt="radio btn off" class="mr-2">
                                        <img *ngIf="checklist_entry.equipment_rating == '1'"
                                            src="/assets/icons/ic_radio_on.svg" alt="radio btn on" class="mr-2">
                                        Performing well
                                    </div>
                                    <div class="col-md-4 d-flex align-items-center bgradio2 txt-radio2 border-right"
                                        (click)="setMntChecklistEquipmentRating('2')">
                                        <img *ngIf="checklist_entry.equipment_rating != '2'"
                                            src="/assets/icons/ic_radio_off.svg" alt="radio btn off" class="mr-2">
                                        <img *ngIf="checklist_entry.equipment_rating == '2'"
                                            src="/assets/icons/ic_radio_on.svg" alt="radio btn on" class="mr-2">
                                        Minor concerns
                                    </div>
                                    <div class="col-md-4 d-flex align-items-center bgradio3 txt-radio3"
                                        (click)="setMntChecklistEquipmentRating('3')">
                                        <img *ngIf="checklist_entry.equipment_rating != '3'"
                                            src="/assets/icons/ic_radio_off.svg" alt="radio btn off" class="mr-2">
                                        <img *ngIf="checklist_entry.equipment_rating == '3'"
                                            src="/assets/icons/ic_radio_on.svg" alt="radio btn on" class="mr-2">
                                        Major concerns
                                    </div>
                                </div>
                                <div *ngIf="checklist_entry.ferror.equipment_rating" class="text-danger fs-085">
                                    Equipment rating required
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="sub-title">Additional Remarks <span class="text-danger">*</span></label>
                                <textarea rows="4" class="form-control" [(ngModel)]="checklist_entry.remarks"
                                    #input_value [class.input-error]="checklist_entry.ferror.remarks" [readonly]="isView"></textarea>
                                <div *ngIf="checklist_entry.ferror.remarks" class="text-danger fs-085">
                                    Additional remarks required
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn btn-primary-txticon mt-4 w-120 float-right"
                        style="background-color:#00AB55 !important" (click)="onComplete()"
                        [disabled]="isView || complete_in_progress">Save&nbsp;&nbsp;<span
                            class="fa fa-angle-right align-end"></span></button>
                </div>
            </div>
        </ng-container>
    </div>
</div>