<div class="top-section">
    <img src="/assets/logos/yellow_triangle_slice1.png" class="triangle-slice">
    <div class="form-group">
        <div class="mb-2">
            <span style="text-decoration: underline;cursor: pointer;"
                (click)="onLinkClick('my-learning')">myLearning</span>
        </div>
        <div class="row">
            <div class="col-md-7 form-group" [class.col-md-12]="!upcoming_session">
                <div class="mb-3 text-bold" style="font-size: 40px;color: #EBB63F;line-height: 1;">
                    Knowledge Series
                </div>
                <div class=" font-size-18">
                    <p>
                        The Knowledge Series is a valuable collection of videos designed specifically for food industry
                        professionals and enthusiasts. It covers essential topics such as product development, process
                        design, manufacturing, automation, and food safety, with carefully chosen content that is both
                        relevant and practical.
                    </p>
                    <p>
                        These sessions feature renowned industry experts from around the world and give you the
                        opportunity
                        to engage directly with our distinguished speakers and panelists, making it easy to get your
                        questions answered and exchange valuable insights.
                    </p>
                </div>
            </div>
            <div *ngIf="upcoming_session" class="col-md-5">
                <div class="session-details round-8">
                    <div class="session-details-head text-bold font-size-16 round-top-8">
                        Upcoming Session
                    </div>
                    <div class="p-3">
                        <div class="form-group">
                            <img *ngIf="upcoming_session.thumbnail_image_blob"
                                [src]="upcoming_session.thumbnail_image_blob | ewurl | async" class="no-blurry"
                                style="max-width: 100%; height: auto;">
                        </div>
                        <div class="sub-head-20">{{upcoming_session.title}}</div>
                        <div class="font-size-16 form-group">
                            <div>{{upcoming_session.date | date}}</div>
                            <div *ngFor="let time of upcoming_session.time_texts">{{time}}</div>
                        </div>
                        <div *ngIf="upcoming_session.speakers_set[0]" class="form-group">
                            <div class="d-flex align-items-center">
                                <div class="border mr-3"
                                    style="width: 48px; height: 48px; border-radius: 50%; overflow: hidden;">
                                    <img *ngIf="upcoming_session.speakers_set[0].profile_photo_blob"
                                        [src]="upcoming_session.speakers_set[0].profile_photo_blob | ewurl | async"
                                        class="no-blurry" style="width: 100%; height: auto;">
                                    <span *ngIf="!upcoming_session.speakers_set[0].profile_photo_blob"
                                        class="profile-pic-letter">
                                        {{upcoming_session.speakers_set[0].name[0]}}
                                    </span>
                                </div>
                                <div class="flex-grow-1">
                                    <div class="sub-head-16">{{upcoming_session.speakers_set[0].name}}</div>
                                    <div class="text-light1 font-size-14">
                                        {{upcoming_session.speakers_set[0].designation}}</div>
                                    <div class="text-light1 font-size-14">
                                        {{upcoming_session.speakers_set[0].organization}}</div>
                                </div>
                                <div *ngIf="upcoming_session.speakers_set.length > 1" class="align-self-start">
                                    <div class="border d-flex align-items-center justify-content-center"
                                        style="height: 32px;width: 32px;border-radius: 24px;font-size: 16px;font-weight: 700;">
                                        +{{upcoming_session.speakers_set.length - 1}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button class="btn btn-primary" (click)="onSessionRegister()">Register Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="layout-padding">
    <div class="sub-head-20 form-group">
        Explore Knowledge Series Videos
    </div>
    <div class="row mb-3">
        <div class="col-md-4 col-sm-10">
            <input type="search" class="form-control" placeholder="Search..." (keyup)="onSearchKeyUp($event)"
                (search)="onSearchKeyUp($event)">
        </div>
        <div class="col-md-2">
            <div class="position-relative">
                <button class="btn btn-outline-custom-primary filter-height hand" id="drop-open"
                    (click)="showFilters = !showFilters">
                    <span class="fa-lg" style="vertical-align: 0%;"><img id="drop-open" [class.fillcolor]="bol_filter"
                            src="/assets/svg-icon/ic_filter.svg"></span>
                    <ng-container *ngIf="bol_filter">&nbsp;&nbsp;
                        <ng-container *ngIf="filter_count == 1">
                            {{filter_count}} Filter Applied
                        </ng-container>
                        <ng-container *ngIf="filter_count != 1">
                            {{filter_count}} Filters Applied
                        </ng-container>
                    </ng-container>
                </button>
                <div *ngIf="showFilters" class="minifilter-form-box clearfix" (clickOutside)="showFilters = false">
                    <span class="fa fa-caret-up fa-2x text-white filter-dialog-show"></span>
                    <div class="form-group">
                        <label>Technologies</label>
                        <app-ew-multi-select2 [options]="technologies" optionValue="id" optionText="name"
                            [(ngModel)]="filter_technologies">
                        </app-ew-multi-select2>
                    </div>
                    <div class="form-group">
                        <label>Industries</label>
                        <app-ew-multi-select2 [options]="industries" optionValue="id" optionText="name"
                            [(ngModel)]="filter_industries" (change)="onIndustryChange()">
                        </app-ew-multi-select2>
                    </div>
                    <div class="form-group">
                        <label>Primary Products</label>
                        <app-ew-multi-select2 [options]="filtered_product_list" optionValue="id" optionText="name"
                            [(ngModel)]="filter_products">
                        </app-ew-multi-select2>
                    </div>
                    <div class="d-flex justify-content-between">
                        <button (click)="onClearFilters()" class="btn btn-sm btn-outline-secondary mr-2 w-50">
                            Reset filters
                        </button>
                        <button (click)="onApplyFilters()" class="btn btn-sm btn-outline-success ml-2 w-50">
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 mb-4  d-flex" *ngFor="let knowledge of knowledge_series"
            (click)="onViewDetails(knowledge)">
            <div class="insight-item flex-fill d-flex flex-column justify-content-between">
                <div>
                    <div class="border form-group" style="height: 215px;overflow: hidden;">
                        <img [src]="knowledge.thumbnail_image_blob | ewurl | async" class="no-blurry bg-white"
                            style="width: 100%; height: auto;">
                    </div>
                    <div appTruncateContent [maxLines]="2" class="sub-head-20 mb-1" style="overflow: hidden;">
                        {{knowledge.title}}
                    </div>
                    <div appTruncateContent class="text-light0 form-group" [innerHTML]="knowledge.description">
                    </div>

                    <div class="text-light1">
                        {{knowledge.month_of_installation}}, {{knowledge.year_of_installation}}
                    </div>
                </div>
                <div *ngIf="knowledge.speakers_set[0]">
                    <div class="border-top mb-2"></div>
                    <div class="d-flex align-items-center">
                        <div class="border mr-3"
                            style="width: 48px; height: 48px; border-radius: 50%; overflow: hidden;">
                            <img *ngIf="knowledge.speakers_set[0].profile_photo_blob"
                                [src]="knowledge.speakers_set[0].profile_photo_blob | ewurl | async" class="no-blurry"
                                style="width: 100%; height: auto;">
                            <span *ngIf="!knowledge.speakers_set[0].profile_photo_blob" class="profile-pic-letter">
                                {{knowledge.speakers_set[0].name[0]}}
                            </span>
                        </div>
                        <div class="flex-grow-1">
                            <div class="sub-head-16">{{knowledge.speakers_set[0].name}}</div>
                            <div class="text-light1 font-size-14">{{knowledge.speakers_set[0].designation}}</div>
                            <div class="text-light1 font-size-14">{{knowledge.speakers_set[0].organization}}</div>
                        </div>
                        <div *ngIf="knowledge.speakers_set.length > 1" class="align-self-start">
                            <div class="border d-flex align-items-center justify-content-center"
                                style="height: 32px;width: 32px;border-radius: 24px;font-size: 16px;font-weight: 700;">
                                +{{knowledge.speakers_set.length - 1}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>