
import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
    isModalShown: boolean = false;
    @Input() title: string;
    @Input() message: string;
    @Input() type: string;
    @Input() btnOkText: string;
    @Input() btnCancelText: string;
    @Input() showInput: boolean;
    @Input() isAlert: boolean;
    @Input() isBLEAlert: boolean;
    name: string;
    isResponsible:boolean = false;
  constructor( private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }
  close() {
    
  }

  hideModel(){
    this.isModalShown = false;
    if(this.showInput) {
      this.activeModal.close("");
    }else {
      this.activeModal.close(false);
    }
   
}

selectErorOption(index){
  if(this.isBLEAlert){
    this.activeModal.close(""+index);
  }
}

confirm(){
  if(this.showInput) {
    if(this.name == undefined){
      this.isResponsible = true;
      return false;
    }
    this.isResponsible = false;
    this.activeModal.close(this.name);
  }else {
    this.activeModal.close(true);
  }
    this.isModalShown = false;
}
}
