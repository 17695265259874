export class User {
    username: number;
    role: string;
    refresh_token: string;
    access_token: string;
    message: string;
    first_name: string;
    last_name: string;
    logo: "";
    user_logo: string;
    company_logo: string;
    user_logo_blob: string;
    company_logo_blob: string;
    user_management_access: boolean;

}

export enum UserType {
    Admin = "Admin",
    OEM = "OEM",
    FoodManufacturer = "Food Manufacturer",
    Reseller = "Reseller",
    MyWinUser = "My Win User",
    Individual = "Individual"
}

export interface Permission {
    module: number;
    module_name: string;
    feature: number;
    feature_name: string;
    view: boolean;
    create: boolean;
    edit: boolean;
    delete: boolean;
    approve: boolean;
    fill: boolean;
    user_type: string;
}

export enum Module {
    MySolutions = "mySolutions",
    myOpportunities = "myOpportunities",
    myProjects = "myProjects",
    myInstallations = "myInstallations",
    myLearning = "myLearning",
    myPartners = "myPartners",
    Other = "Other"
}

export enum Feature {
    SellableSolutions = "Sellable solutions",
    NonSellableSolutions = "Non-sellable solutions",
    DraftSolutions = "Draft Solutions",
    DocumentUpload = "Document upload",
    UseCases = "Use cases",
    Opportunities = "Opportunities",
    FMProject = "Project",
    ProjectCustomerInformation = "Project - Customer information",
    DynamicDataQualifiersFiltersROIRFQ = "Dynamic data - Qualifiers, Filters, ROI. RFQ",
    DynamicDataTimelineStatus = "Dynamic data - Timeline, Status",
    DynamicDataChecklistSpareList = "Dynamic data - Checklist, Spare list",
    SolutionLayout = "Solution layout",
    InstallationsCustomerInfo = "Installations - Customer and installation information",
    InstallationLayout = "Installation layout",
    Equipment = "Equipment",
    DocumentUploadFMPart = "Document upload - FM part",
    ChecklistFillingRatingSupportRequest = "Checklist - Filling, Rating, Support Request",
    SparePartsRequest = "Spare parts - Request",
    OEMAssignment = "OEM Assignment",
    ProcessLines = "Process Lines",
    PartnerInformation = 'Partner information - Name, Location',
    PartnerAssignment = "Partner assignment - Country, solutions, roles",
    ContactsAssignment = "Partner contacts for myWin platform",
    UserManagement = "User Management",
    NotificationConfiguration = "Notification Configuration"
}
export interface SolutionModel {
    id?: number;
    model: string;
    capacity: string;
    capacity_uom: number;
    capacity_uom_name: string;
    selected_uom: any;
    f_error: any;
}

export interface RolePermission {
    id: number;
    feature_name: string;
    module_name: string;
    module: number;
    view: boolean;
    create: boolean;
    edit: boolean;
    delete: boolean;
    approve: boolean;
    fill: boolean;
    feature: number;
    role: number;
}

export interface Subscription {
    name: string;
    modules: string;
}

export interface CurrentUserInfo {
    username: string;
    user_type: string;
    access_token: string;
    company_logo: string;
    company_logo_blob: string;
    domain_list: any[]; // Adjust to specific type if available
    email_domain: string;
    existing_domain: boolean;
    first_name: string;
    last_name: string;
    message: string;
    oem_org_currency: string;
    refresh_token: string;
    reseller_function_assign: any[]; // Adjust to specific type if available
    reseller_status: string;
    role: string;
    role_permissions: RolePermission[];
    show_subscribe_button: boolean;
    subscription: Subscription;
    subscription_enabled: boolean;
    subscription_modules: any[]; // Adjust to specific type if available
    subscription_name: string;
    support_request_enabled: boolean;
    user_logo: string;
    user_logo_blob: string | null;
}