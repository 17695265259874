import { Component, ElementRef, forwardRef, HostListener, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { addDays, addMonths, endOfMonth, format, isSameDay, isValid, setMonth, setYear, startOfMonth, subMonths, subYears } from 'date-fns';

export const EW_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EwDatepickerComponent),
  multi: true
};

const noop = () => {
};

@Component({
  selector: 'app-ew-datepicker',
  templateUrl: './ew-datepicker.component.html',
  styleUrls: ['./ew-datepicker.component.scss'],
  providers: [EW_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class EwDatepickerComponent implements OnInit, ControlValueAccessor {

  private onModelTouched: () => void = noop;
  private onModelChange: (_: any) => void = noop;

  private wasInside = false;

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (!this.wasInside && !this.eRef.nativeElement.contains(event.target)) {
      this.showCalendar = false;
    }
    this.wasInside = false;
  }

  @Input() set selectedDate(value: any) {
    this.setInputValue(value);
  }
  @Input() align_left = false;

  @Input() set read_only(value: any) {
    this._read_only = value;
  }

  @Input() set date_format(value: any) {
    this._date_format = value;
  }

  @Input() set height_sm(value: any) {
    this._height_sm = value;
  }

  _selectedDate = new Date();
  selectedDateText = '';
  _date_format = 'dd-MM-yyyy';
  showCalendar = false;
  showDates = true;
  showMonths = false;
  showYears = false;
  today = new Date();
  view = 'days';
  _read_only = false;
  _height_sm = false;

  years = [];
  namesOfDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  months = [{ id: 0, name: 'January' }, { id: 1, name: 'February' }, { id: 2, name: 'March' }, { id: 3, name: 'April' }, { id: 4, name: 'May' },
  { id: 5, name: 'June' }, { id: 6, name: 'July' }, { id: 7, name: 'August' }, { id: 8, name: 'September' }, { id: 9, name: 'October' },
  { id: 10, name: 'November' }, { id: 11, name: 'December' }];
  weeks = [];

  constructor(private eRef: ElementRef) { }

  ngOnInit(): void {
    this.generateCalendar();
  }

  writeValue(value: any): void {
    this.setInputValue(value);
  }

  setInputValue(value) {
    if (value && isValid(new Date(value))) {
      this._selectedDate = new Date(value);
      this.generateCalendar();
      this.selectedDateText = format(this._selectedDate, this._date_format);
    } else {
      this._selectedDate = new Date();
      this.selectedDateText = '';
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  onDateSelect(date) {
    this._selectedDate = date;
    this._selectedDate.setHours(new Date().getHours());
    this._selectedDate.setMinutes(new Date().getMinutes());
    this.selectedDateText = format(date, this._date_format);
    this.onModelChange(this._selectedDate);
    this.showCalendar = false;
  }

  onMonthSelect(month) {
    this._selectedDate = setMonth(this._selectedDate, month.id);
    this.generateCalendar();
    this.selectedDateText = format(this._selectedDate, this._date_format);
    this.view = 'days';
  }

  onMonthMove(dir) {
    if (dir) {
      this._selectedDate = subMonths(this._selectedDate, 1);
    } else {
      this._selectedDate = addMonths(this._selectedDate, 1);
    }
    this.generateCalendar();
    this.selectedDateText = format(this._selectedDate, this._date_format);
    this.view = 'days';
  }

  onYearSelect(year) {
    this._selectedDate = setYear(this._selectedDate, year);
    this.generateCalendar();
    this.selectedDateText = format(this._selectedDate, this._date_format);
    this.view = 'months';
  }

  onYearMove(dir) {
    if (dir) {
      let offset_year = Math.min(...this.years);
      this.years = [];
      for (let i = 1; i <= 9; i++) {
        this.years.push(offset_year - i);
      }
    } else {
      let offset_year = Math.max(...this.years);
      this.years = [];
      for (let i = 1; i <= 9; i++) {
        this.years.push(offset_year + i);
      }
    }
    this.years.sort((a, b) => a - b);
  }

  isSelectedDate(date): boolean {
    return isSameDay(new Date(date), this._selectedDate);
  }

  fillYears(offsetYear) {

  }

  generateCalendar(): void {
    const dates = this.fillDates(this._selectedDate);
    const weeks = [];
    while (dates.length > 0) {
      weeks.push(dates.splice(0, 7));
    }
    this.weeks = weeks;

    this.years = [];
    let current_year = this._selectedDate.getFullYear();
    this.years.push(current_year);
    for (let i = 1; i <= 4; i++) {
      this.years.push(current_year - i);
      this.years.push(current_year + i);
    }
    this.years.sort((a, b) => a - b);
  }

  fillDates(date: Date): any[] {
    let dates = [];
    const firstDayOfMonth = startOfMonth(date).getDay();
    const firstDateOfMonth = startOfMonth(date).getDate();
    const lastDayOfMonth = endOfMonth(date).getDay();
    const lastDateOfMonth = endOfMonth(date).getDate();

    let empty_days = 0
    while (empty_days < firstDayOfMonth) {
      dates.push('');
      empty_days++;
    }

    let _first_date = startOfMonth(date).getDate() - 1;
    while (_first_date < lastDateOfMonth) {
      const next_date = addDays(startOfMonth(date), _first_date);
      dates.push(next_date);
      _first_date++;
    }

    return dates;
  }
}
