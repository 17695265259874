import { Component, OnInit, Inject, HostListener, OnDestroy, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, takeWhile } from 'rxjs/operators';
import { AuthenticationService } from '../service/authentication.service';
import { AlertService } from '../service/alert.service';
import { Business } from '../service/Business.service';
import { CountryTypes } from '../model/countryType'
import { AppConfig, APP_CONFIG } from '../app.config';
import { UserService } from '../service/user.service';
import { Subscription, interval } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @HostListener('document:scroll', ['$event'])
  scrollHandler(event) {
    console.debug("Scroll Event");
  }

  loginForm: FormGroup;
  registrationSupplierForm: FormGroup;
  registrationEndUserForm: FormGroup;
  forgotPasswordForm: FormGroup;
  resetPasswordForm: FormGroup;
  loading = false;
  submitted = false;
  forgotSubmit = false;
  resetSubmit = false;
  showForgotPassword: boolean;
  showLoginForm: boolean;
  showResetPassword: boolean;
  showRegistrationForm: boolean;
  showRegistrationSupplierForm: boolean;
  showRegistrationEndUserForm: boolean;
  returnUrl: string;

  public roleType: [];
  public CountriesType: CountryTypes[];
  showLoading: boolean;
  public file: File;
  private imageSrc: string = '';

  selctedPhoneCode = '';
  ewTitle = '';

  lastScrollTopMyContent = 0;

  login = true;

  mobile_view = false;
  scrWidth: number;

  newUser = { name: '', email_address: '', platform: 'website' };
  confirmPwd = '';
  ferror = { name: false, email: false, inValidEmail: false };

  slideShowModuleIndex = 1;
  slideShowIntervalSubscription: Subscription;
  bsModalRef: BsModalRef;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth > 768) {
      this.mobile_view = false;
    } else {
      this.mobile_view = true;
    }
  }

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private businessService: Business,
    private alertService: AlertService,
    private modalService: BsModalService
  ) {
    this.getScreenSize();
    this.ewTitle = config.title;
    this.showForgotPassword = false;
    this.showLoginForm = true;
    this.showResetPassword = false;
    this.showRegistrationForm = false;

    //window.onscroll = function () { this.scrollFunction() }

  }

  @HostListener('window:scroll', ['$event']) getScrollHeight(event) {
    this.scrollFunction()
  }

  scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById('gotoTop').style.display = "block";
      document.getElementById("navbar").style.background = "#FFFFFF";
      //document.getElementById("navbar").style.width = "100%";
    } else {
      document.getElementById('gotoTop').style.display = "none";
      document.getElementById("navbar").style.background = "none";
      //document.getElementById("navbar").style.width = "none";
    }

  }

  ngOnInit() {
    this.slide_module_init();
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required]

    });

    this.forgotPasswordForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]]
    });

    this.resetPasswordForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      newpassword: ['', Validators.required],
      resetcode: ['', Validators.required],
    });

    this.registrationSupplierForm = this.formBuilder.group({
      roleType: [null, Validators.required],

      supplierusername: ['', Validators.required],
      supplierlogo: ['', Validators.required],
      website: ['', [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
      supplieremail: ['', [Validators.required, Validators.email]],
      contactpersonname: ['', Validators.required],
      contactpersonnumber: ['', [Validators.required, Validators.pattern('^[0-9]{6,10}$')]],
      hqcountry: [null, Validators.required],
      supplycountry: [null, Validators.required]
    });


    this.registrationEndUserForm = this.formBuilder.group({

      endusername: ['', Validators.required],
      designation: ['', Validators.required],
      company: ['', Validators.required],
      enduseremail: ['', [Validators.required, Validators.email]],
      location: ['', Validators.required],
      country: [null, Validators.required],
      contactnumber: ['', [Validators.required, Validators.pattern('^[0-9]{6,10}$')]]
    });

    // reset login status
    this.authenticationService.logout();


  }

  ngOnDestroy(): void {
    if (this.slideShowIntervalSubscription) {
      this.slideShowIntervalSubscription.unsubscribe();
    }
  }

  slide_module_init() {
    if (this.slideShowIntervalSubscription) {
      this.slideShowIntervalSubscription.unsubscribe();
    }

    this.slideShowIntervalSubscription = interval(3000).subscribe(() => {
      this.slideShowModuleIndex = (this.slideShowModuleIndex % 3) + 1;
    });
  }

  onViewModule(module_number) {
    if (this.slideShowIntervalSubscription) {
      this.slideShowIntervalSubscription.unsubscribe();
    }

    this.slideShowModuleIndex = module_number;
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  // convenience getter for easy access to form fields
  get forgot() { return this.forgotPasswordForm.controls; }
  // convenience getter for easy access to form fields
  get reset() { return this.resetPasswordForm.controls; }

  get registrationsupplier() { return this.registrationSupplierForm.controls; }

  get registrationenduser() { return this.registrationEndUserForm.controls; }

  onTabsToggle(showLoginForm) {
    this.showLoginForm = showLoginForm;
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    //  this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.businessService.getConfigurations().subscribe((resp) => {
            sessionStorage.setItem('EW_CONFIGS', JSON.stringify(resp));
          });
          // if (!(data.user_type == "Admin" || data.user_type == "My Win User") && !data.subscription_enabled) {
          //   this.alertService.error('Subscription is not enabled for your account, Please contact admin for more information', true);
          //   this.router.navigate(['/login']);
          //   return;
          // }
          // if ((data.user_type != "Admin" && data.user_type != "Individual") && !data.role) {
          //   this.alertService.error('No role assigned for your account, Please contact admin for more information', true);
          //   this.router.navigate(['/login']);
          //   return;
          // }

          if (this.authenticationService.redirectUrl) {
            let url = this.authenticationService.redirectUrl;
            if (url.includes('my-learning') || url.includes('knowledge-series') || url.includes('insight')) {
              if (data.user_type == "Individual") {
                url = url.replace(/customer|oem/, 'individual')
              } else if (data.user_type == "Food Manufacturer") {
                url = url.replace(/individual|oem/, 'customer')
              } else if (data.user_type == "OEM") {
                url = url.replace(/customer|individual/, 'oem')
              }
            }
            this.router.navigateByUrl(url);
          } else {
            this.authenticationService.goToUserHomePage();
          }

        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        });
  }

  onregistrationSupplierSubmit() {
    this.submitted = true;

    // reset alerts on submit
    //  this.alertService.clear();

    // stop here if form is invalid
    if (this.registrationSupplierForm.invalid) {
      return;
    }

    this.businessService.supplierRegistration(this.registrationsupplier.supplierusername.value, this.imageSrc,
      this.registrationsupplier.website.value, this.registrationsupplier.supplieremail.value, this.registrationsupplier.contactpersonname.value,
      this.selctedPhoneCode + ' ' + this.registrationsupplier.contactpersonnumber.value, this.registrationsupplier.hqcountry.value, this.registrationsupplier.supplycountry.value)
      .subscribe(
        data => {
          this.alertService.success(data.message);
          // this.router.navigate(['']);
          window.location.reload();
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        });

  }



  onregistrationEndUserSubmit() {
    this.submitted = true;

    // reset alerts on submit
    //  this.alertService.clear();

    // stop here if form is invalid
    if (this.registrationEndUserForm.invalid) {
      return;
    }

    this.businessService.enduserRegistration(this.registrationenduser.endusername.value, this.registrationenduser.designation.value,
      this.registrationenduser.company.value, this.registrationenduser.enduseremail.value, this.registrationenduser.location.value,
      this.selctedPhoneCode + ' ' + this.registrationenduser.contactnumber.value)
      .subscribe(
        data => {
          this.alertService.success(data.message);
          // this.router.navigate(['']);
          window.location.reload();
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        });
  }


  getCountries(): void {
    this.businessService.getAllCountries()
      .subscribe(
        data => {
          this.showLoading = false;
          this.CountriesType = data.map((i) => { return i; });;
        },
        error => {
          this.alertService.error(error);
          this.showLoading = false;
        }
      );
  }


  showForm(formName): void {
    if (formName == 'Forgot') {
      this.router.navigate(['/forgot-password']);
      return;
      // this.showForgotPassword = true;
      // this.showLoginForm = false;
      // this.showResetPassword = false;
      // this.showRegistrationForm = false;

    }
    if (formName == 'Login') {
      this.showForgotPassword = false;
      this.showLoginForm = true;
      this.showResetPassword = false;
      this.showRegistrationForm = false;
      this.showRegistrationEndUserForm = false;
      this.registrationSupplierForm.reset();
      this.registrationEndUserForm.reset();
    }

    if (formName == 'Reset') {
      this.showForgotPassword = false;
      this.showLoginForm = false;
      this.showResetPassword = true;
      this.showRegistrationForm = false;

    }

    if (formName == 'Registration') {
      this.showForgotPassword = false;
      this.showLoginForm = false;
      this.showResetPassword = false;
      this.showRegistrationForm = true;
      this.showRegistrationSupplierForm = true;
      this.showRegistrationEndUserForm = false;

      this.getCountries();



    }
  }


  roleitems = [
    { id: 1, name: 'Supplier' },
    { id: 2, name: 'End-User' }

  ];
  selected = [
    { id: 1, name: 'Supplier' }
  ];

  getRoleValues() {
    this.selctedPhoneCode = '';
    const roleId = this.registrationSupplierForm.controls['roleType'].value;

    if (roleId.id == 1) {
      this.showRegistrationSupplierForm = true;
      this.showRegistrationEndUserForm = false;
    }

    if (roleId.id == 2) {
      this.showRegistrationSupplierForm = false;
      this.showRegistrationEndUserForm = true;
    }

  }

  onHQCountryChange(isEndUser = false) {
    this.selctedPhoneCode = '';
    let countryAlpha3 = '';
    if (isEndUser) {
      countryAlpha3 = this.registrationEndUserForm.controls['country'].value;
    } else {
      countryAlpha3 = this.registrationSupplierForm.controls['hqcountry'].value;
    }
    const country: any = this.CountriesType.find((item: any) => item.alpha3 == countryAlpha3);
    if (country && country.phone_code) {
      this.selctedPhoneCode = country.phone_code;
    }
  }

  onFileChanged(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.imageSrc = reader.result;

  }

  onScrollMyContent(event: MouseEvent) {
    event.preventDefault();
    const st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > this.lastScrollTopMyContent) {
      // downscroll code
    } else {
      // upscroll code
    }
    document.getElementById('my-solutions').scrollIntoView({ behavior: "smooth" });
    this.lastScrollTopMyContent = st <= 0 ? 0 : st;
  }

  onMoveTop() {
    window.scroll({
      top: 0, left: 0, behavior: 'smooth'
    });
  }



  //login form

  validate(): boolean {
    this.ferror = { name: false, email: false, inValidEmail: false };
    if (!this.newUser.name) {
      this.ferror.name = true;
    }

    if (!this.newUser.email_address) {
      this.ferror.email = true;
    } else if (!this.config.emailPattern.test(this.newUser.email_address)) {
      this.ferror.inValidEmail = true;
    }

    // if (!this.newUser.password) {
    //   this.ferror.password = true;
    // } else if (!(this.confirmPwd && this.confirmPwd === this.newUser.password)) {
    //   this.ferror.confirmPwd = true;
    // }

    const eKeys = Object.keys(this.ferror);
    const isValid = eKeys.every((ek) => this.ferror[ek] == false);
    return isValid;
  }

  onRegister() {
    if (!this.validate()) return;
    this.userService.register(this.newUser).subscribe((resp) => {
      this.newUser = { name: '', email_address: '', platform: 'website' };
      this.alertService.success(resp.message || 'Account created successfully', true);
      setTimeout(() => {
        document.location.href = '/';
      }, 2000);
    }, (err) => this.alertService.error(err));
  }

  onRequestDemo(template: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(template)
  }

}
