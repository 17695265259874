import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdminModule } from './admin.module';
import { APP_CONFIG, AppConfig } from '../app.config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  baseUrl = '';
  formDataHeader = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
  constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient) {
    this.baseUrl = this.config.baseUrl;
  }

  editProcessLIne = null;

  getAllCurrency(): Observable<any> {
    const url = this.baseUrl + 'get_currency/';
    return this.http.get<any>(url);
  }

  getAllEquipments(): Observable<any> {
    const _params = new HttpParams()
      .set('query', '')
      .set('page', '0')
      .set('sort_by', 'name');
    const url = this.baseUrl + 'admin/equipments/';
    return this.http.get<any>(url, { params: _params });
  }

  getAllSystems(): Observable<any> {
    const _params = new HttpParams()
      .set('query', '')
      .set('page', '0')
      .set('sort_by', 'name');
    const url = this.baseUrl + 'admin/systems/';
    return this.http.get<any>(url, { params: _params });
  }

  addEquipment(equipment): Observable<any> {
    const url = this.baseUrl + 'admin/equipments/';
    return this.http.post<any>(url, equipment)
  }

  updateEquipment(equipment): Observable<any> {
    const url = this.baseUrl + 'admin/equipments/';
    return this.http.put<any>(url, equipment)
  }

  checkDuplicateName(name: string, id?: string): Observable<{ is_duplicate: boolean }> {
    let url = `${this.baseUrl}admin/check-duplicate-equipment-name/?name=${name}`;
    if (id) {
      url += `&id=${id}`;
    }
    return this.http.get<{ is_duplicate: boolean }>(url);
  }

  addSystem(system): Observable<any> {
    const url = this.baseUrl + 'admin/systems/';
    return this.http.post<any>(url, system)
  }

  updatesystem(system): Observable<any> {
    const url = this.baseUrl + 'admin/systems/';
    return this.http.put<any>(url, system)
  }

  getAllPrimaryProducts(): Observable<any> {
    const _params = new HttpParams()
      .set('query', '')
      .set('page', '0')
      .set('sort_by', 'name');
    const url = this.baseUrl + 'admin/primary_product/';
    return this.http.get(url, { params: _params });
  }

  getPrimaryProductsForDropdown(): Observable<any> {
    const url = this.baseUrl + 'admin/primary_products/';
    return this.http.get<any>(url);
  }

  getAdminProcessLines(primaryProduct, searchText, pageNumber, sortBy): Observable<any> {
    const url = this.baseUrl + 'admin/process_lines/?' + 'primary_product=' + primaryProduct + '&query=' + searchText + '&page=' + pageNumber + '&sort_by=' + sortBy;
    return this.http.get(url);
  }

  getAdminProcessLineById(lineId): Observable<any> {
    const url = this.baseUrl + 'admin/process_lines/?' + 'line_id=' + lineId;
    return this.http.get(url);
  }

  createAdminProcessLine(processLine): Observable<any> {
    const url = this.baseUrl + 'admin/process_lines/';
    return this.http.post<any>(url, processLine);
  }

  updateAdminProecessLine(processLine): Observable<any> {
    const url = this.baseUrl + 'admin/process_lines/';
    return this.http.put<any>(url, processLine);
  }

  removeProcessLine(pid) {
    const url = this.baseUrl + 'admin/process_lines/?' + 'line_id=' + pid;
    return this.http.delete(url);
  }

  checkCanDeleteEquipment(eId) {
    const url = this.baseUrl + 'admin/delete_verification_equipment/';
    const _params = new HttpParams().set('equipment_id', eId);
    return this.http.get<any>(url, { params: _params });
  }

  checkCanDeleteSystem(sId) {
    const url = this.baseUrl + 'admin/delete_verification_system/';
    const _params = new HttpParams().set('system_id', sId);
    return this.http.get<any>(url, { params: _params });
  }

  checkCanDeleteProcessLine(lineId) {
    const url = this.baseUrl + 'admin/delete_verification_line/';
    const _params = new HttpParams().set('line_id', lineId);
    return this.http.get<any>(url, { params: _params });
  }

  getAllPrimaryProducts1(): Observable<any> {
    const _params = new HttpParams()
      .set('all', 'true');
    const url = this.baseUrl + 'admin/primary_product/';
    return this.http.get(url, { params: _params });
  }

  getPrimaryProducts(searchText, pageNumber, sortBy): Observable<any> {
    const _params = new HttpParams()
      .set('search', searchText)
      .set('page', pageNumber)
      .set('ordering', sortBy);
    const url = this.baseUrl + 'admin/primary_product/';
    return this.http.get(url, { params: _params });
  }

  addPrimaryProduct(product): Observable<any> {
    const formData = new FormData();
    formData.append('name', product.name);
    formData.append('description', product.description);
    formData.append('primary_product_image', product.primary_product_image);
    formData.append('industry', product.industry);
    const url = this.baseUrl + 'admin/primary_product/';
    return this.http.post(url, formData);
  }

  updatePrimaryProduct(product): Observable<any> {
    const formData = new FormData();
    formData.append('name', product.name);
    formData.append('description', product.description);
    formData.append('primary_product_image', product.primary_product_image);
    formData.append('industry', product.industry);
    const url = this.baseUrl + 'admin/primary_product/' + product.id + '/';
    return this.http.put(url, formData);
  }

  deletePrimaryProduct(productId): Observable<any> {
    const url = this.baseUrl + 'admin/primary_product/' + productId + '/';
    return this.http.delete(url);
  }

  checkCanDeletePrimaryProduct(lineId) {
    const url = this.baseUrl + 'admin/delete_verification_primary_product';
    const _params = new HttpParams().set('line_id', lineId);
    return this.http.get<any>(url, { params: _params });
  }

  getUsers(searchText, pageNumber, sortBy, userType, companyName, company_id, status, role, country, subscribed): Observable<any> {
    let url = this.baseUrl + 'account/user_list/';
    let _params = new HttpParams()
      .set('query', searchText)
      .set('page', pageNumber)
      .set('sort_by', sortBy)
      .set('user_type', userType)
      .set('status', status)
      .set('company', companyName)
      .set('company_id', company_id)
      .set('ew_role', role)
      .set('country', country);
    if (subscribed == 'true') {
      _params = new HttpParams()
        .set('query', searchText)
        .set('page', pageNumber)
        .set('sort_by', sortBy)
        .set('user_type', userType)
        .set('status', status)
        .set('company', companyName)
        .set('company_id', company_id)
        .set('ew_role', role)
        .set('country', country)
        .set('subscription_status', subscribed);
    }
    return this.http.get<any>(url, { params: _params });
  }

  getNewOEMS(): Observable<any> {
    const url = this.baseUrl + 'account/oem_list/';
    const _params = new HttpParams()
      .set('query', 'new');
    return this.http.get<any>(url, { params: _params });
  }

  getCompaniesList(): Observable<any> {
    const url = this.baseUrl + 'account/oem_list/';
    return this.http.get<any>(url);
  }

  updateUser(user): Observable<any> {
    const url = this.baseUrl + 'account/modify_user/';
    return this.http.post(url, user);
  }

  toogleUserStatus(user): Observable<any> {
    const url = this.baseUrl + 'account/modify_user/?id=' + user.id;
    return this.http.put(url, user);
  }

  approveSubscribedUser(user, role): Observable<any> {
    const url = this.baseUrl + 'account/approve_user/' + user.id + '/' + role + '/';
    return this.http.post(url, user);
  }

  getAllModules(): Observable<any> {
    const url = this.baseUrl + 'role_module_list/';
    return this.http.get<any>(url);
  }

  getAllRoles(): Observable<any> {
    const url = this.baseUrl + 'role_list/';
    const _params = new HttpParams()
      .set('all', 'true');
    return this.http.get<any>(url, { params: _params });
  }

  getRoles(sort_by, search_text, pageNumber): Observable<any> {
    const url = this.baseUrl + 'role_list/';
    const _params = new HttpParams()
      .set('ordering', sort_by)
      .set('search', search_text)
      .set('page', pageNumber);
    return this.http.get<any>(url, { params: _params });
  }

  addRole(role: any): Observable<any> {
    const url = this.baseUrl + 'role_list/';
    return this.http.post(url, role);
  }

  updateRole(role: any): Observable<any> {
    const url = this.baseUrl + 'role_details/' + role.id + '/';
    return this.http.put<any>(url, role);
  }

  getRoleDetails(roleId: any): Observable<any> {
    const url = this.baseUrl + 'role_details/' + roleId + '/';
    return this.http.get<any>(url).pipe(map(resp => {
      return resp;
    }));
  }

  deleteRole(roleId: any): Observable<any> {
    const url = this.baseUrl + 'role_details/' + roleId + '/';
    return this.http.delete<any>(url);
  }

  getAllSubscriptions(): Observable<any> {
    const url = this.baseUrl + 'subscription_list/';
    const _params = new HttpParams()
      .set('all', 'true');
    return this.http.get<any>(url, { params: _params });
  }

  getSubscriptions(sort_by, search_text, pageNumber): Observable<any> {
    const url = this.baseUrl + 'subscription_list/';
    const _params = new HttpParams()
      .set('ordering', sort_by)
      .set('search', search_text)
      .set('page', pageNumber);
    return this.http.get<any>(url, { params: _params });
  }

  addSubscription(subscription: any): Observable<any> {
    const url = this.baseUrl + 'subscription_list/';
    return this.http.post(url, subscription);
  }

  updateSubscription(subscription: any): Observable<any> {
    const url = this.baseUrl + 'subscription_details/' + subscription.id + '/';
    return this.http.put<any>(url, subscription);
  }

  getSubscriptionDetails(id: any): Observable<any> {
    const url = this.baseUrl + 'subscription_details/' + id + '/';
    return this.http.get<any>(url);
  }

  deleteSubscription(id: any): Observable<any> {
    const url = this.baseUrl + 'subscription_details/' + id + '/';
    return this.http.delete<any>(url);
  }

  getAllIndustries(): Observable<any> {
    const url = this.baseUrl + 'industry_list/';
    const _params = new HttpParams()
      .set('all', 'true');
    return this.http.get<any>(url, { params: _params });
  }

  getIndustries(sort_by, search_text): Observable<any> {
    const url = this.baseUrl + 'industry_list/';
    const _params = new HttpParams()
      .set('ordering', sort_by)
      .set('search', search_text);
    return this.http.get<any>(url, { params: _params });
  }

  addIndustry(industry: any): Observable<any> {
    const url = this.baseUrl + 'industry_list/';
    return this.http.post(url, industry);
  }

  updateIndustry(industry: any): Observable<any> {
    const url = this.baseUrl + 'industry_details/' + industry.id + '/';
    return this.http.put<any>(url, industry);
  }

  getIndustryDetails(industryId: any): Observable<any> {
    const url = this.baseUrl + 'industry_details/' + industryId + '/';
    return this.http.get<any>(url);
  }

  deleteIndustry(industryId: any): Observable<any> {
    const url = this.baseUrl + 'industry_details/' + industryId + '/';
    return this.http.delete<any>(url);
  }

  getAllTechnologies(): Observable<any> {
    const url = this.baseUrl + 'technology_list/';
    const _params = new HttpParams()
      .set('all', 'true');
    return this.http.get<any>(url, { params: _params });
  }

  getTechnologies(sort_by, search_text): Observable<any> {
    const url = this.baseUrl + 'technology_list/';
    const _params = new HttpParams()
      .set('ordering', sort_by)
      .set('search', search_text);
    return this.http.get<any>(url, { params: _params });
  }

  addTechnology(technology: any): Observable<any> {
    const url = this.baseUrl + 'technology_list/';
    return this.http.post(url, technology);
  }

  updateTechnology(technology: any): Observable<any> {
    const url = this.baseUrl + 'technology_details/' + technology.id + '/';
    return this.http.put<any>(url, technology);
  }

  getTechnologyDetails(technologyId: any): Observable<any> {
    const url = this.baseUrl + 'technology_details/' + technologyId + '/';
    return this.http.get<any>(url);
  }

  deleteTechnology(technologyId: any): Observable<any> {
    const url = this.baseUrl + 'technology_details/' + technologyId + '/';
    return this.http.delete<any>(url);
  }

  getUserTypes(sort_by, search_text): Observable<any> {
    const url = this.baseUrl + 'user_types/';
    return this.http.get<any>(url);
  }

  getModules(sort_by, search_text): Observable<any> {
    const url = this.baseUrl + 'modules_list/';
    return this.http.get<any>(url);
  }

  getFeatures(sort_by, search_text): Observable<any> {
    const url = this.baseUrl + 'features_list/';
    return this.http.get<any>(url);
  }

  getUserDetails(userId): Observable<any> {
    const url = this.baseUrl + 'account/user_list/?id=' + userId;
    return this.http.get<any>(url);
  }

  addUser(user: any): Observable<any> {
    const url = this.baseUrl + 'account/create_update_user/';
    return this.http.post<any>(url, user);
  }

  updateUserV2(user: any): Observable<any> {
    const url = this.baseUrl + 'account/create_update_user/';
    return this.http.put<any>(url, user);
  }

  deactivateUser(userId: any): Observable<any> {
    const url = this.baseUrl + 'account/create_update_user/?id=' + userId;
    return this.http.delete<any>(url);
  }

  downloadUsers(searchText, pageNumber, sortBy, userType, companyName, status, role, country): Observable<any> {
    const url = this.baseUrl + 'account/user_list/';
    const _params = new HttpParams()
      .set('query', searchText)
      .set('page', pageNumber)
      .set('sort_by', sortBy)
      .set('user_type', userType)
      .set('status', status)
      .set('company', companyName)
      .set('ew_role', role)
      .set('country', country)
      .set('csv_export', 'true');
    return this.http.get<any>(url, { params: _params });
  }

  downloadUsersUploadTemplate(templateType: string): Observable<any> {
    const url = this.baseUrl + 'account/bulk_user_upload/?template=' + templateType;
    return this.http.get<any>(url)
  }

  uploadBulkUsers(users_file: any, file_type: string, templateType: string): Observable<any> {
    const url = this.baseUrl + 'account/bulk_user_upload/?template=' + templateType;
    const formData: FormData = new FormData();
    formData.append(file_type, users_file);
    return this.http.post<any>(url, formData);
  }

  getAllFirmGroups(): Observable<any> {
    const url = this.baseUrl + 'firm_group_list/';
    return this.http.get<any>(url);
  }

  addFirmGroup(firmgroup: any): Observable<any> {
    const url = this.baseUrl + 'firm_group_list/';
    return this.http.post(url, firmgroup);
  }

  updateFirmGroup(firmgroup: any): Observable<any> {
    const url = this.baseUrl + 'firm_group_details/' + firmgroup.id + '/';
    return this.http.put<any>(url, firmgroup);
  }

  deleteFirmGroup(id: any): Observable<any> {
    const url = this.baseUrl + 'firm_group_details/' + id + '/';
    return this.http.delete<any>(url);
  }

  getAllOrganizations(): Observable<any> {
    const url = this.baseUrl + 'organization_list/';
    const _params = new HttpParams()
      .set('all', 'true');
    return this.http.get<any>(url, { params: _params });
  }

  getOrganizations(sort_by, search_text, page_no): Observable<any> {
    const url = this.baseUrl + 'organization_list/';
    const _params = new HttpParams()
      .set('ordering', sort_by)
      .set('search', search_text)
      .set('page', page_no);
    return this.http.get<any>(url, { params: _params });
  }

  getOrganizationsList(sort_by, search_text, page_no): Observable<any> {
    const url = this.baseUrl + 'org_list/';
    const _params = new HttpParams()
      .set('sort_by', sort_by)
      .set('query', search_text)
      .set('page', page_no);
    return this.http.get<any>(url, { params: _params });
  }

  getLatestOrgId(): Observable<any> {
    const url = this.baseUrl + 'latest_org_id/';
    return this.http.get(url);
  }

  addOrganization(org: any): Observable<any> {
    const url = this.baseUrl + 'organization_list/';
    return this.http.post(url, org);
  }

  updateOrganization(org: any): Observable<any> {
    const url = this.baseUrl + 'organization_details/' + org.id + '/';
    return this.http.put<any>(url, org);
  }

  getOrganizationDetails(orgId: any): Observable<any> {
    const url = this.baseUrl + 'organization_details/' + orgId + '/';
    return this.http.get<any>(url);
  }

  deleteOrganization(orgId: any): Observable<any> {
    const url = this.baseUrl + 'organization_details/' + orgId + '/';
    return this.http.delete<any>(url);
  }

  getOrganizationsDropdownList(): Observable<any> {
    const url = this.baseUrl + 'org_dropdown_list/';
    return this.http.get<any>(url);
  }

  canDeleteLocationOrGroup(id, type): Observable<any> {
    const url = this.baseUrl + 'account/delete_verification_location_group/';
    return this.http.post<any>(url, { id: id, group_or_location: type });
  }

  checkOrgNameExist(name): Observable<any> {
    const url = this.baseUrl + 'check_org_name_exist?name=' + name;
    return this.http.get<any>(url);
  }

  getCountryName(alpha3, countries): string {
    if (!alpha3) return alpha3;
    const country = countries.find(x => x.alpha3 == alpha3);
    if (country) {
      return country.name;
    }
    return alpha3;
  }

  //analytics apis...
  getPageViews(query, value, range = '7d'): Observable<any> {
    const url = this.baseUrl + `admin/analytics/?query=${query}&value=${value}&range=${range}`;
    return this.http.get<any>(url);
  }

  getAvgTimeSpent(list_by, range = '7d'): Observable<any> {
    const url = this.baseUrl + `admin/analytics/?list_by=${list_by}&range=${range}`;
    return this.http.get<any>(url);
  }

  getOverviewValues(range): Observable<any> {
    const url = this.baseUrl + `admin/analytics/?range=${range}`;
    return this.http.get<any>(url);
  }

  getAvgTimeSpentByUser(user_id, range = '7d'): Observable<any> {
    const url = this.baseUrl + `admin/analytics/?list_by=page&query=user&value=${user_id}&range=${range}`;
    return this.http.get<any>(url);
  }

  getAnalyticsData(query, value = '', range = '7d', graph_no): Observable<any> {
    const url = this.baseUrl + `admin/analytics/?list_by=${query}&value=${value}&range=${range}&graph_number=${graph_no}`;
    return this.http.get<any>(url);
  }

  getAllEndUserProcessLines(): Observable<any> {
    const _params = new HttpParams()
      .set('primary_product', 'all')
      .set('plant_location', '')
      .set('query', '')
      .set('page', '0')
      .set('sort_by', 'name');
    const url = this.baseUrl + 'end_user/process_line/';
    return this.http.get(url, { params: _params });
  }

  getAllOrganizations1(): Observable<any> {
    const url = this.baseUrl + 'org_dropdown_list/';
    return this.http.get<any>(url);
  }

  getAllUsers(): Observable<any> {
    const url = this.baseUrl + 'account/user_list/';
    return this.http.get<any>(url);
  }

  logDownloadFile(doc_id): Observable<any> {
    const url = this.baseUrl + 'admin/manual_log/?document_id=' + doc_id;
    return this.http.post<any>(url, null);
  }

  getCountryList(query): Observable<any> {
    const url = this.baseUrl + `country_list/?query=${query}`;
    return this.http.get<any>(url);
  }

  getHelpRequest(sort_by, search_text, pageNumber): Observable<any> {
    const url = this.baseUrl + 'help_notification/';
    const _params = new HttpParams()
      .set('ordering', sort_by)
      .set('page', pageNumber)
      .set('search', search_text);
    return this.http.get<any>(url, { params: _params });
  }

  getJoinOrgRequest(sort_by, search_text, pageNumber): Observable<any> {
    const url = this.baseUrl + 'org_request/';
    const _params = new HttpParams()
      .set('ordering', sort_by)
      .set('page', pageNumber)
      .set('search', search_text);
    return this.http.get<any>(url, { params: _params });
  }

  checkUserSlotsAvailable(org_id): Observable<any> {
    const url = `${this.baseUrl}check_user_slot/?oem_org=${org_id}`;
    return this.http.get<any>(url);
  }

  checkOrgWebsiteURLExist(website): Observable<any> {
    const url = `${this.baseUrl}check_org_website_exist/?website=${website}`;
    return this.http.get<any>(url);
  }

  getFMOrgRequests(): Observable<any> {
    const url = this.baseUrl + 'account/fm_org_request_list';
    return this.http.get<any>(url);
  }

  getFMOrgRequestDetails(id): Observable<any> {
    const url = this.baseUrl + `account/fm_org_request_details/${id}/`;
    return this.http.get<any>(url);
  }

  getFMLocationRequests(): Observable<any> {
    const url = this.baseUrl + 'account/fm_org_loc_list';
    return this.http.get<any>(url);
  }

  getFMLocationRequestDetails(id): Observable<any> {
    const url = this.baseUrl + `account/fm_loc_request_details/${id}/`;
    return this.http.get<any>(url);
  }

  getExistingFMOrgs(): Observable<any> {
    const url = this.baseUrl + `account/existing_fms_list/`;
    return this.http.get<any>(url);
  }

  getExistingFMOrgLocations(id): Observable<any> {
    const url = this.baseUrl + `account/org_locations_master/?org_id=${id}`;
    return this.http.get<any>(url);
  }

  fmOrgRequestApproval(approval): Observable<any> {
    const url = `${this.baseUrl}account/fm_org_request_approval/`;
    return this.http.post<any>(url, approval);
  }

  fmLocRequestApproval(approval): Observable<any> {
    const url = `${this.baseUrl}account/fm_loc_request_approval/`;
    return this.http.post<any>(url, approval);
  }

  getAllRequestsCount(): Observable<any> {
    const url = this.baseUrl + `all_requests_count/`;
    return this.http.get<any>(url);
  }

  getFMOrgDecisionMakers(sort_by, search_text, pageNumber, fm_org, fm_org_request, created_by_org): Observable<any> {
    const _params = new HttpParams()
      .set('ordering', sort_by)
      .set('page', pageNumber)
      .set('search', search_text)
      .set('fm_org', fm_org)
      .set('fm_org_request', fm_org_request)
      .set('created_by_organization', created_by_org);
    const url = `${this.baseUrl}supplier/fm_org_dms_list/`;
    return this.http.get<any>(url, { params: _params });
  }

  getAllDMsFMOrganizations(): Observable<any> {
    const url = `${this.baseUrl}supplier/decision_makers_fms_filter_drop_down/`;
    return this.http.get<any>(url);
  }

  getAllDMsCreatedByOrganizations(): Observable<any> {
    const url = `${this.baseUrl}supplier/decision_makers_created_by_orgs_filter_drop_down/`;
    return this.http.get<any>(url);
  }

  getOEMOrgRequests(): Observable<any> {
    const url = this.baseUrl + 'account/oem_org_request_list/';
    return this.http.get<any>(url);
  }

  getOEMOrgRequestDetails(id): Observable<any> {
    const url = this.baseUrl + `account/oem_org_request_details/${id}/`;
    return this.http.get<any>(url);
  }

  oemOrgRequestApproval(approval): Observable<any> {
    const url = `${this.baseUrl}account/oem_org_request_approval/`;
    return this.http.post<any>(url, approval);
  }

  getVimeoVideoEmbedDetails(id): Observable<any> {
    const url = `${this.baseUrl}vimeo_videos/${id}/`;
    return this.http.get<any>(url);
  }

  getAllExpertUsers(): Observable<any> {
    const url = `${this.baseUrl}admin/expert_users_list/?dropdown=true`;
    return this.http.get<any>(url);
  }

  getExpertUsers(): Observable<any> {
    const url = this.baseUrl + 'admin/expert_users_list/';
    return this.http.get<any>(url);
  }

  addExpertUser(expert): Observable<any> {
    const url = this.baseUrl + `admin/expert_users_list/`;
    return this.http.post<any>(url, expert);
  }

  updateExpertUser(expert): Observable<any> {
    const url = this.baseUrl + `admin/expert_users_details/${expert?.id}/`;
    return this.http.put<any>(url, expert);
  }

  deleteExpertUser(id): Observable<any> {
    const url = this.baseUrl + `admin/expert_users_details/${id}/`;
    return this.http.delete<any>(url);
  }

  getInsights(searchText, pageNumber, sortBy, technologies, industries, products): Observable<any> {
    const url = this.baseUrl + 'admin/insights_list/';
    const _params = new HttpParams()
      .set('search', searchText)
      .set('page', pageNumber)
      .set('ordering', sortBy)
      .set('technologies', technologies)
      .set('industries', industries)
      .set('products', products);
    return this.http.get<any>(url, { params: _params });
  }

  getInsightDetails(id): Observable<any> {
    const url = this.baseUrl + `admin/insights_details/${id}/`;
    return this.http.get<any>(url);
  }

  addInsight(insight): Observable<any> {
    const url = this.baseUrl + `admin/insights_list/`;
    return this.http.post<any>(url, insight);
  }

  updateInsight(insight): Observable<any> {
    const url = this.baseUrl + `admin/insights_details/${insight?.id}/`;
    return this.http.put<any>(url, insight);
  }

  deleteInsight(id): Observable<any> {
    const url = this.baseUrl + `admin/insights_details/${id}/`;
    return this.http.delete<any>(url);
  }

  getKnowledgeSeries(searchText, pageNumber, sortBy, technologies, industries, products): Observable<any> {
    const url = this.baseUrl + 'admin/knowledge_series_list/';
    const _params = new HttpParams()
      .set('search', searchText)
      .set('page', pageNumber)
      .set('ordering', sortBy)
      .set('technologies', technologies)
      .set('industries', industries)
      .set('products', products);
    return this.http.get<any>(url, { params: _params });
  }

  getKnowledgeSeriesDetails(id): Observable<any> {
    const url = this.baseUrl + `admin/knowledge_series_details/${id}/`;
    return this.http.get<any>(url);
  }

  addKnowledgeSeries(knowledge_series): Observable<any> {
    const url = this.baseUrl + `admin/knowledge_series_list/`;
    return this.http.post<any>(url, knowledge_series);
  }

  updateKnowledgeSeries(knowledge_series): Observable<any> {
    const url = this.baseUrl + `admin/knowledge_series_details/${knowledge_series?.id}/`;
    return this.http.put<any>(url, knowledge_series);
  }

  deleteKnowledgeSeries(id): Observable<any> {
    const url = this.baseUrl + `admin/knowledge_series_details/${id}/`;
    return this.http.delete<any>(url);
  }

  getUpcomingSessions(searchText, pageNumber, sortBy): Observable<any> {
    const url = this.baseUrl + 'admin/upcoming_sessions_list/';
    const _params = new HttpParams()
      .set('search', searchText)
      .set('page', pageNumber)
      .set('ordering', sortBy);
    return this.http.get<any>(url, { params: _params });
  }

  getUpcomingSessionDetails(id): Observable<any> {
    const url = this.baseUrl + `admin/upcoming_sessions_details/${id}/`;
    return this.http.get<any>(url);
  }

  addUpcomingSession(upcoming_session): Observable<any> {
    const url = this.baseUrl + `admin/upcoming_sessions_list/`;
    return this.http.post<any>(url, upcoming_session);
  }

  updateUpcomingSession(upcoming_session): Observable<any> {
    const url = this.baseUrl + `admin/upcoming_sessions_details/${upcoming_session?.id}/`;
    return this.http.put<any>(url, upcoming_session);
  }

  deleteUpcomingSession(id): Observable<any> {
    const url = this.baseUrl + `admin/upcoming_sessions_details/${id}/`;
    return this.http.delete<any>(url);
  }
}
