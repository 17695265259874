import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { Data } from "./data.service";
import { environment } from 'src/environments/environment';
import { param } from 'jquery';
import { AppConstants } from '../app.constants';
import { catchError, map } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../app.config';
import { CacheService } from './cache.service';


// import { Users, UserModel } from "../model/users.model";
// import { DeviceType } from "../model/devicetype.model";
// import { EnvService } from '../env.service';
// import { subscriptionProduct } from '../model/subscriptionproduct.model';
// import { subscriptionOffer } from '../model/subscriptionoffer.model';
// import { subscriptionPlan} from '../model/subscriptionplan.model';
// import { AppConstants } from '../app.constants';
// import { UserDeviceHistory } from "../model/userdevicehistory.model";
// import { UtilityHelper } from '../_helpers/utility.helper';

@Injectable({
  providedIn: "root"
})
export class Business {
  footerSubject: Subject<boolean> = new Subject<boolean>();
  baseUrl = '';

  constructor(
    private DataService: Data, private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private cacheService: CacheService
  ) {
    this.baseUrl = this.config.baseUrl;
  }

  supplierRegistration(supplierusername: string, supplierlogo: string, website: string, supplieremail: string,
    contactpersonname: string, contactpersonnumber: string, hqcountry: string, supplycountry: string): Observable<any> {
    let body = {

      "supplierlogo": supplierlogo,
      "name": supplierusername,
      "contact_person_number": contactpersonnumber,
      "contact_person_name": contactpersonname,
      "hq_country": hqcountry,
      "supply_country": supplycountry,
      "website": website,
      "email_address": supplieremail

    }
    return this.DataService.postData(environment.DEV_DOMAIN_URL + 'account/signup_supplier/', body, '')
  }

  enduserRegistration(endusername: string, designation: string, company: string, enduseremail: string, location: string, contactnumber: string): Observable<any> {
    let body = {
      "company": company,
      "name": endusername,
      "contact_number": contactnumber,
      "designation": designation,
      "location": location,
      "email_address": enduseremail
    }
    return this.DataService.postData(environment.DEV_DOMAIN_URL + 'account/signup_user/', body, '')
  }

  forgotPassword(username: any): Observable<any> {
    // let body =
    // {
    //   email: username
    // }
    const body = new HttpParams()
      .set('email', username);

    return this.DataService.postData(environment.DEV_DOMAIN_URL + 'account/forgot_password/', body.toString(), { headers: AppConstants.AUTH_HEADERS })
  }

  needMoreInfoSupplierEquipment(id, remarks): Observable<any> {  // remarks
    let body = {
      "equipment_id": id,
      "remarks": remarks
    }
    return this.DataService.postData(environment.DEV_DOMAIN_URL + 'admin/need_more_info/', body, '')
  }


  approveSupplierEquipment(id): Observable<any> {
    let params = {
      "equipment_id": id
    }
    return this.DataService.postData(environment.DEV_DOMAIN_URL + 'admin/approve_supplier_equipment/?equipment_id=' + id, "", "");
  }

  // getAllEquipmentsDropDown(): Observable<any> {

  // return this.DataService.getData(environment.DEV_DOMAIN_URL + 'equipment_list_drop_down/', '');
  // }

  // getAllSystemsDropDown(): Observable<any> {

  // return this.DataService.getData(environment.DEV_DOMAIN_URL + 'system_list_drop_down/', '');
  // }

  getAllEquipmentsbyQuery(searchText, pageNumber, sortBy): Observable<any> {

    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'admin/equipments/?' + 'query=' + searchText + '&page=' + pageNumber + '&sort_by=' + sortBy, '');

  }

  getEquipmentById(id): Observable<any> {
    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'admin/equipments/?' + 'id=' + id, '');
  }

  getProcessById(id): Observable<any> {
    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'admin/systems/?' + 'id=' + id, '').pipe(
      catchError(err => {
        return of(null);
      })
    );
  }

  getLineById(id): Observable<any> {
    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'admin/process_lines/?' + 'id=' + id, '').pipe(
      catchError(err => {
        return of(null);
      })
    );
  }

  getAllSystemlistsDropDown(): Observable<any> { //Nagendra confusing api

    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'admin/equipments/', '');
  }

  getAllSystemslistbyQuery(searchText, pageNumber, sortBy): Observable<any> {

    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'admin/systems/?' + 'query=' + searchText + '&page=' + pageNumber + '&sort_by=' + sortBy, "");

  }

  getAllSupplierlistbyQuery(searchText, pageNumber, sortBy): Observable<any> {

    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'account/suppliers/?' + 'query=' + searchText + '&page=' + pageNumber + '&sort_by=' + sortBy, "");

  }

  getAllEndUserlistbyQuery(searchText, pageNumber, sortBy): Observable<any> {

    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'account/end_users/?' + 'query=' + searchText + '&page=' + pageNumber + '&sort_by=' + sortBy, "");

  }

  getAllRoles(): Observable<any> {
    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'account/user_role/', '');
  }

  getAllCountries(): Observable<any> {
    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'get_countries/', '');
  }

  getResellerUserCountries(oem_org_id): Observable<any> {
    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'reseller_country_list/?' + 'oem_org_id=' + oem_org_id, '');
  }

  getAllSupplierEquipments(searchText, pageNumber, sortBy): Observable<any> {

    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'admin/approve_supplier_equipment/?' + 'query=' + searchText + '&page=' + pageNumber + '&sort_by=' + sortBy, '');
  }


  deleteEquipment(id): Observable<any> {
    let params = {
      "equipment_id": id
    }
    return this.DataService.deleteData(environment.DEV_DOMAIN_URL + 'admin/equipments/?equipment_id=' + id);
  }

  deleteSupplierlist(id): Observable<any> {

    return this.DataService.deleteData(environment.DEV_DOMAIN_URL + 'account/modify_user/?id=' + id);
  }


  deleteEndUser(id): Observable<any> {

    return this.DataService.deleteData(environment.DEV_DOMAIN_URL + 'account/modify_user/?id=' + id);
  }


  deleteSystemList(id): Observable<any> {

    return this.DataService.deleteData(environment.DEV_DOMAIN_URL + 'admin/systems/?system_id=' + id);
  }



  updateEquipment(id: string, name: string, modified_on: string, created_by: string): Observable<any> {
    let body = {
      "id": id,
      "name": name,
      "modified_on": modified_on,
      "created_by": created_by
    }
    return this.DataService.putData(environment.DEV_DOMAIN_URL + 'admin/equipments/', body, '')
  }

  updateSystem(id: string, name: string, modified_on: string, created_by: string): Observable<any> {
    let body = {
      "id": id,
      "name": name,
      "modified_on": modified_on,
      "created_by": created_by
    }
    return this.DataService.putData(environment.DEV_DOMAIN_URL + 'admin/systems/', body, '')
  }

  updateSupplierlist(id: string, name: string, contact_person_name: string, contact_number: string): Observable<any> {
    let body = {
      "id": id,
      "company_name": name,
      "contact_person_name": contact_person_name,
      "contact_number": contact_number
    }
    return this.DataService.postData(environment.DEV_DOMAIN_URL + 'account/modify_user/', body, '')
  }

  updateEndUser(id: string, name: string, designation: string, company_name: string, location: string, contact_number: string): Observable<any> {
    let body = {
      "id": id,
      "contact_person_name": name,
      "designation": designation,
      "company_name": company_name,
      "location": location,
      "contact_number": contact_number
    }
    return this.DataService.postData(environment.DEV_DOMAIN_URL + 'account/modify_user/', body, '')
  }

  addEquipment(name: string, description: string, file: string): Observable<any> {
    let body = {
      "name": name,
      "description": name,
      "equipment_image": file
    }

    return this.DataService.postData(environment.DEV_DOMAIN_URL + 'admin/equipments/', body, '')
  }

  addSystem(name: string, description: string, file: string, euipmentIds: string): Observable<any> {
    let body = {
      "name": name,
      "description": name,
      "system_image": file,
      "equipment_sequence": euipmentIds
    }
    return this.DataService.postData(environment.DEV_DOMAIN_URL + 'admin/systems/', body, '')
  }

  addSupplierEquipment(formData: any, warrantyDate: Date, installation_date: Date, validationDate: Date, equipmentIds: string): Observable<any> {
    let body = {
      "id": formData.id,
      "name": formData.name,
      "description": formData.description,
      "model_number": formData.model_number,
      "model_name": formData.model_name,
      "warranty_expiry": warrantyDate,
      "installation_date": installation_date,
      "order_number": formData.order_number,
      "origin_country": formData.origin_country,
      "validation_date": validationDate,
      "qr_code_tag": formData.qr_code_tag,
      "equipment_ids": equipmentIds

    }
    if (formData.id) {
      return this.DataService.putData(environment.DEV_DOMAIN_URL + 'supplier/equipments/', body, '')
    }
    else {
      return this.DataService.postData(environment.DEV_DOMAIN_URL + 'supplier/equipments/', body, '')
    }
  }

  // userTaggedEquipmentList(searchText, pageNumber, sortBy, location, line, system, supplier_id, primary, equipment_select, installed_by = '', fm_id = ''): Observable<any> {

  //   return this.DataService.getData(environment.DEV_DOMAIN_URL + 'end_user/tag_equipment/?' + 'query=' + searchText +
  //     '&page=' + pageNumber + '&sort_by=' + sortBy + '&org_location=' + location + '&line=' + line + '&system=' + system +
  //     '&supplier_id=' + supplier_id + '&primary=' + primary + '&equipment_select=' + equipment_select + '&installed_by=' + installed_by + '&fm_id=' + fm_id, "");
  // }

  /* getEquipmentId(id: string): Observable<any> {
    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'end_user/tag_equipment/?' + 'id=' + id, "");
  } */

  fmTaggedEquipmentList(searchText, pageNumber, sortBy, location, line, system, supplier_id, primary, equipment_select, installed_by = '', status, origin): Observable<any> {
    const cacheKey = this.cacheService.generateCacheKey(this.fmTaggedEquipmentList.name, [
      searchText, pageNumber, sortBy, location, line, system, supplier_id, primary, equipment_select,
      installed_by, status, origin
    ]);
    const cacheData = this.cacheService.get(cacheKey);
    if (cacheData) return of(cacheData);
    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'tagged_equipment_list/?' + 'search=' + searchText +
      '&page=' + pageNumber + '&ordering=' + sortBy + '&process_line__org_location__name=' + location + '&process_line__name=' +
      line + '&system=' + system + '&oem_org_id=' + supplier_id + '&process_line__primary_product_ref__name=' +
      primary + '&equipment_select=' + equipment_select + '&process_line__installed_by=' + installed_by + '&status=' + status + '&tagged_by=' + origin, "").pipe(
        map((resp: any) => {
          this.cacheService.set(cacheKey, resp);
          return resp;
        })
      );
  }

  fmOEMTaggedEquipmentList(tagged_by, status_txt, equipment_id, fm_org): Observable<any> {

    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'tagged_equipment_list/?' + 'tagged_by=' + tagged_by +
      '&status=' + status_txt + '&equipment_id=' + equipment_id + '&end_user_org=' + fm_org + '&assigned=true', "");
  }

  postFile(id: string, name: string, fileToUpload: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('document', fileToUpload);
    formData.append('equipment_id', id);
    formData.append('name', name);
    let body = {
      "equipment_id": id,
      "name": name,
      "document": fileToUpload
    }
    return this.DataService.postData(environment.DEV_DOMAIN_URL + 'supplier/upload_document/', formData, '')
  }

  supplierEquipmentList(searchText, pageNumber, sortBy): Observable<any> {

    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'supplier/equipments/?' + 'query=' + searchText + '&page=' + pageNumber + '&sort_by=' + sortBy, "");
  }

  supplierTaggedEquipmentList(searchText, pageNumber, sortBy): Observable<any> {
    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'supplier/tagged_equipments/?' + 'query=' + searchText + '&page=' + pageNumber + '&sort_by=' + sortBy, '');
  }

  deleteSupplierEquipment(id): Observable<any> {
    return this.DataService.deleteData(environment.DEV_DOMAIN_URL + 'supplier/equipments/?equipment_id=' + id);
  }

  resetUserPassword(email): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('email', email);
    return this.DataService.postData(environment.DEV_DOMAIN_URL + 'account/forgot_password/', formData, '');
  }

  filterListView(filter, parent, query): Observable<any> {
    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'end_user/filter_list_view/?filter=' + filter + '&parent=' + parent + '&query=' + query, '');
  }

  filterSupplierListView(): Observable<any> {
    return this.DataService.getData(environment.DEV_DOMAIN_URL + 'end_user/filter_supplier_list_view/', '');
  }

  getRegisteredCompaniesList(): Observable<any> {
    const url = environment.DEV_DOMAIN_URL + 'account/oem_list/';
    return this.http.get<any>(url);
  }

  getConfigurations(): Observable<any> {
    const url = environment.DEV_DOMAIN_URL + 'admin/configurations/';
    return this.http.get<any>(url);
  }

  saveConfigurations(configs): Observable<any> {
    const url = environment.DEV_DOMAIN_URL + 'admin/configurations/';
    return this.http.post<any>(url, configs);
  }


  getAllPrimaryProducts1(query = null): Observable<any> {
    let _params = new HttpParams()
      .set('all', 'true');
    if (query) {
      _params = new HttpParams().set('all', 'true').set('query', query);
    }
    const url = environment.DEV_DOMAIN_URL + 'admin/primary_product/';
    return this.http.get(url, { params: _params });
  }

  getAllIndustries(): Observable<any> {
    const url = environment.DEV_DOMAIN_URL + 'industry_drop_list/';
    return this.http.get<any>(url);
  }

  getAllTechnologies(): Observable<any> {
    const url = environment.DEV_DOMAIN_URL + 'technology_drop_list/';
    return this.http.get<any>(url);
  }

  deleteEquipmentDocument(installed_equipment_id, doc_id): Observable<any> {
    return this.DataService.deleteData(
      environment.DEV_DOMAIN_URL + 'supplier/delete_document/equipment/?installed_equipment_id=' + installed_equipment_id + '&document_id=' + doc_id);
  }

  checkMachineSerialNumber(machine_serial, oem_org_id = null): Observable<any> {
    return this.http.get<any>(`${environment.DEV_DOMAIN_URL}supplier/check_machine_serial/${machine_serial}/?oem_org_id=${oem_org_id}`)
  }


  // getOrgLocations(org_id = ''): Observable<any> {
  //   const url = `${environment.DEV_DOMAIN_URL}account/org_locations_list/?org_id=${org_id}`;
  //   return this.http.get<any>(url);
  // }

  getPlantLocations(org_id = ''): Observable<any> {
    const url = `${environment.DEV_DOMAIN_URL}account/org_locations_list/`;
    let _params = new HttpParams()
      .set('org_id', org_id)
      .set('plant', 'true');
    return this.http.get<any>(url, { params: _params });
  }


  getBase64ByFileId(id: number): Observable<any> {
    const url = `${environment.DEV_DOMAIN_URL}document_to_base64/${id}/`
    return this.http.get<any>(url);
  }

  setFooterSubject(display: boolean): any {
    this.footerSubject.next(display);
  }

  getFooterSubject(): Observable<boolean> {
    return this.footerSubject.asObservable();
  }

  getAzureBlobUrl(blob_name): Observable<any> {
    return this.http.post(environment.DEV_DOMAIN_URL + 'azure_blob_url/', { blob_name: blob_name });
  }

  getAzureBlobBase64(blob_name): Observable<any> {
    return this.http.post(environment.DEV_DOMAIN_URL + `azure_blob_url/`, { blob_name: blob_name, base64: true });
  }

  uploadFileToStorage(file: File, id = ''): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('ew_file', file);

    return this.http.post(environment.DEV_DOMAIN_URL + 'upload_azure_blob/', formData);
  }

  uploadEWDocument(document_obj): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('ew_file', document_obj.file);
    formData.append('document_label_fk', document_obj?.document_label_fk);
    formData.append('document_label', document_obj?.document_label);
    formData.append('document_type', document_obj?.document_type);
    formData.append('document_label_ids', document_obj?.document_label_ids.join());

    return this.http.post(environment.DEV_DOMAIN_URL + 'upload_ew_document/', formData);
  }

  deleteEWDocument(document_obj): Observable<any> {
    return this.http.put(environment.DEV_DOMAIN_URL + 'delete_ew_document/', document_obj);
  }


  // insight, knowledge series apis...

  getAllKnowledgeSeries(searchText, technologies, industries, products): Observable<any> {
    const cacheKey = this.cacheService.generateCacheKey(this.getAllKnowledgeSeries.name, [
      searchText, technologies, industries, products
    ]);
    const cacheData = this.cacheService.get(cacheKey);
    if (cacheData) return of(cacheData);
    const url = this.baseUrl + 'admin/knowledge_series_list/?all=true';
    const _params = new HttpParams()
      .set('search', searchText)
      .set('technologies', technologies)
      .set('industries', industries)
      .set('products', products);
    return this.http.get<any>(url, { params: _params }).pipe(
      map((resp: any) => {
        this.cacheService.set(cacheKey, resp);
        return resp;
      })
    );
  }

  getRelatedKnowledgeSeries(related_knowledge_series_id): Observable<any> {
    const url = this.baseUrl + 'admin/knowledge_series_list/';
    const _params = new HttpParams()
      .set('related_knowledge_series_id', related_knowledge_series_id)
    return this.http.get<any>(url, { params: _params });
  }

  getInsightDetails(id): Observable<any> {
    const url = this.baseUrl + `admin/insights_details/${id}/`;
    return this.http.get<any>(url);
  }

  getAllInsights(searchText, technologies, industries, products): Observable<any> {
    const cacheKey = this.cacheService.generateCacheKey(this.getAllInsights.name, [
      searchText, technologies, industries, products
    ]);
    const cacheData = this.cacheService.get(cacheKey);
    if (cacheData) return of(cacheData);
    const url = this.baseUrl + 'admin/insights_list/?all=true';
    const _params = new HttpParams()
      .set('search', searchText)
      .set('technologies', technologies)
      .set('industries', industries)
      .set('products', products);
    return this.http.get<any>(url, { params: _params }).pipe(
      map((resp: any) => {
        this.cacheService.set(cacheKey, resp);
        return resp;
      })
    );
  }

  getRelatedInsights(related_insight_id): Observable<any> {
    const url = this.baseUrl + 'admin/insights_list/';
    const _params = new HttpParams()
      .set('related_insight_id', related_insight_id)
    return this.http.get<any>(url, { params: _params });
  }

  getKnowledgeSeriesDetails(id): Observable<any> {
    const url = this.baseUrl + `admin/knowledge_series_details/${id}/`;
    return this.http.get<any>(url);
  }

  updateInsightView(view_obj): Observable<any> {
    const url = this.baseUrl + `admin/insights_view_list/`;
    return this.http.post<any>(url, view_obj);
  }

  addInsightComment(comment): Observable<any> {
    const url = this.baseUrl + `admin/insights_comment_list/`;
    return this.http.post<any>(url, comment);
  }

  updateInsightComment(comment): Observable<any> {
    const url = this.baseUrl + `admin/insights_comment_details/${comment.id}/`;
    return this.http.put<any>(url, comment);
  }

  deleteInsightComment(id): Observable<any> {
    const url = this.baseUrl + `admin/insights_comment_details/${id}/`;
    return this.http.delete<any>(url);
  }

  addInsightCommentReply(comment): Observable<any> {
    const url = this.baseUrl + `admin/insights_comment_reply_list/`;
    return this.http.post<any>(url, comment);
  }

  updateInsightCommentReply(comment): Observable<any> {
    const url = this.baseUrl + `admin/insights_comment_reply_details/${comment.id}/`;
    return this.http.put<any>(url, comment);
  }

  deleteInsightCommentReply(id): Observable<any> {
    const url = this.baseUrl + `admin/insights_comment_reply_details/${id}/`;
    return this.http.delete<any>(url);
  }

  updateKnowledgeSeriesView(view_obj): Observable<any> {
    const url = this.baseUrl + `admin/knowledge_series_view_list/`;
    return this.http.post<any>(url, view_obj);
  }

  addKnowledgeSeriesComment(comment): Observable<any> {
    const url = this.baseUrl + `admin/knowledge_series_comment_list/`;
    return this.http.post<any>(url, comment);
  }

  updateKnowledgeSeriesComment(comment): Observable<any> {
    const url = this.baseUrl + `admin/knowledge_series_comment_details/${comment.id}/`;
    return this.http.put<any>(url, comment);
  }

  deleteKnowledgeSeriesComment(id): Observable<any> {
    const url = this.baseUrl + `admin/knowledge_series_comment_details/${id}/`;
    return this.http.delete<any>(url);
  }

  addKnowledgeSeriesCommentReply(comment): Observable<any> {
    const url = this.baseUrl + `admin/knowledge_series_comment_reply_list/`;
    return this.http.post<any>(url, comment);
  }

  updateKnowledgeSeriesCommentReply(comment): Observable<any> {
    const url = this.baseUrl + `admin/knowledge_series_comment_reply_details/${comment.id}/`;
    return this.http.put<any>(url, comment);
  }

  deleteKnowledgeSeriesCommentReply(id): Observable<any> {
    const url = this.baseUrl + `admin/knowledge_series_comment_reply_details/${id}/`;
    return this.http.delete<any>(url);
  }

  getCurrentUpcomingSessionDetails(): Observable<any> {
    const url = this.baseUrl + `admin/current_upcoming_session/`;
    return this.http.get<any>(url);
  }

  getLinkTokenData(token): Observable<any> {
    const url = `${this.baseUrl}account/link_token_data/?token=${token}`;
    return this.http.get<any>(url);
  }

  addUOM(name) {
    const url = `${this.baseUrl}capacity_uom_list/`;
    return this.http.post<any>(url, { uom_name: name });
  }

  getCapacityUOMs(): Observable<any> {
    const url = `${this.baseUrl}capacity_uom_list/?all=true`;
    return this.http.get<any>(url);
  }
}
