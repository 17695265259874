<div class="calendar">
    <div *ngIf="!_read_only" class="input-group" (click)="showCalendar = !showCalendar">
        <input type="text" class="form-control" readonly [(ngModel)]="selectedDateText"
            [class.form-control-sm]="_height_sm" [class.view-date]="!_read_only">
        <div class="input-group-append border-left">
            <span class="input-group-text fa fa-calendar fa-lg hand edit-mode-icon" style="padding-top: 10px;"></span>
        </div>
    </div>
    <div *ngIf="_read_only" class="form-control bg-readonly border-0 font-size-14" [class.form-control-sm]="_height_sm">
        {{selectedDateText}}
    </div>
    <div *ngIf="showCalendar" class="calendar-body" [style.right]="align_left ? '0' : 'initial'">
        <div class="text-white text-center py-2" style="background-color: #2b59c0;">
            <span class="mx-3 hand fa fa-angle-left fa-lg" (click)="onMonthMove(true)"></span>
            <div class="hand d-inline-block" style="width: 70px;" (click)="view='months'">{{_selectedDate |
                date:'MMMM'}}</div>
            <span class="mx-3 hand fa fa-angle-right fa-lg" (click)="onMonthMove(false)"></span>
            <span class="hand" (click)="view='years'">{{_selectedDate | date:'yyyy'}}</span>
        </div>
        <div *ngIf="view=='days'">
            <table class="table">
                <thead>
                    <tr class="no-shadow">
                        <td class="text-secondary" *ngFor="let day of namesOfDays">{{day}}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let week of weeks" class="no-shadow text-center">
                        <td (click)="onDateSelect(date)" class="date" [class.active-date]="isSelectedDate(date)"
                            *ngFor="let date of week">{{date | date:'d'}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="view=='months'" class="">
            <div class="month" *ngFor="let month of months" (click)="onMonthSelect(month)">
                <span class="">{{month.name | slice:0:3}}</span>
            </div>
        </div>
        <div *ngIf="view=='years'">
            <div class="year" *ngFor="let year of years" (click)="onYearSelect(year)">
                <span>{{year}}</span>
            </div>
            <div class="form-group text-center text-secondary">
                <span class="mx-3 hand fa fa-chevron-left fa-lg" (click)="onYearMove(true)"></span>
                <span class="mx-3 hand fa fa-chevron-right fa-lg" (click)="onYearMove(false)"></span>
            </div>
        </div>
    </div>
</div>