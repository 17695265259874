import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG, AppConfig } from "../app.config";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class InstalledEquipmentService {
    baseUrl = '';

    constructor(
        @Inject(APP_CONFIG) private config: AppConfig,
        private http: HttpClient
    ) {
        this.baseUrl = this.config.baseUrl;
    }

    submitRFQSparePart(rfq): Observable<any> {
        const url = `${this.baseUrl}end_user/rfq_spare_part/`;
        return this.http.put(url, rfq);
    }
    getSolutionMntChecklistDetails(checklist_id): Observable<any> {
        const url = this.baseUrl + 'solution_mnt_checklist_details/' + checklist_id + '/';
        return this.http.get<any>(url);
    }

    addSolutionMntChecklist(checklist): Observable<any> {
        const url = this.baseUrl + 'solution_mnt_checklist/';
        return this.http.post<any>(url, checklist);
    }

    updateSolutionMntChecklist(checklist): Observable<any> {
        const url = this.baseUrl + 'solution_mnt_checklist_details/' + checklist.id + '/';
        return this.http.put<any>(url, checklist);
    }

    getSolutionHealthChecklistDetails(checklist_id): Observable<any> {
        const url = this.baseUrl + 'solution_health_checklist_details/' + checklist_id + '/';
        return this.http.get<any>(url);
    }

    addSolutionHealthChecklist(checklist): Observable<any> {
        const url = this.baseUrl + 'solution_health_checklist/';
        return this.http.post<any>(url, checklist);
    }

    updateSolutionHealthChecklist(checklist): Observable<any> {
        const url = this.baseUrl + 'solution_health_checklist_details/' + checklist.id + '/';
        return this.http.put<any>(url, checklist);
    }

    getInstallationChecklistById(checklist_id): Observable<any> {
        const url = `${this.baseUrl}installation_mnt_checklist/`;
        let _params = new HttpParams().set('checklist_id', checklist_id);
        return this.http.get<any>(url, { params: _params });
    }

    addInstallationMntChecklist(checklist): Observable<any> {
        const url = this.baseUrl + 'installation_mnt_checklist/';
        return this.http.post<any>(url, checklist);
    }

    updateInstallationMntChecklist(checklist): Observable<any> {
        const url = this.baseUrl + 'installation_mnt_checklist/';
        return this.http.put<any>(url, checklist);
    }


    getInstallationHealthChecklistById(checklist_id): Observable<any> {
        const url = `${this.baseUrl}installation_health_checklist/`;
        let _params = new HttpParams().set('checklist_id', checklist_id);
        return this.http.get<any>(url, { params: _params });
    }

    addInstallationHealthChecklist(checklist): Observable<any> {
        const url = this.baseUrl + 'installation_health_checklist/';
        return this.http.post<any>(url, checklist);
    }

    updateInstallationHealthChecklist(checklist): Observable<any> {
        const url = this.baseUrl + 'installation_health_checklist/';
        return this.http.put<any>(url, checklist);
    }

    getSolutionMntChecklist(sort_by, search_text, page_no, checklist_org = ''): Observable<any> {
        const url = this.baseUrl + 'solution_mnt_checklist/';
        const _params = new HttpParams()
            .set('ordering', sort_by)
            .set('search', search_text)
            .set('page', page_no)
            .set('oem_organization', checklist_org);
        return this.http.get<any>(url, { params: _params });
    }

    getSolutionHealthChecklist(sort_by, search_text, page_no, checklist_org = ''): Observable<any> {
        const url = this.baseUrl + 'solution_health_checklist/';
        const _params = new HttpParams()
            .set('ordering', sort_by)
            .set('search', search_text)
            .set('page', page_no)
            .set('oem_organization', checklist_org);
        return this.http.get<any>(url, { params: _params });
    }

    getInstallationChecklist(installation_id, supplier_equipment_id): Observable<any> {
        const url = `${this.baseUrl}installation_mnt_checklist/`;
        let _params = new HttpParams();
        if (installation_id) {
            _params = new HttpParams().set('installation_id', installation_id);
        }
        if (supplier_equipment_id) {
            _params = new HttpParams().set('supplier_equipment_id', supplier_equipment_id);
        }
        return this.http.get<any>(url, { params: _params });
    }

    getInstallationHealthChecklist(): Observable<any> {
        const url = `${this.baseUrl}installation_health_checklist/`;
        let _params = new HttpParams();
        return this.http.get<any>(url, { params: _params });
    }

    deleteSolutionMntChecklist(checklist_id): Observable<any> {
        const url = this.baseUrl + 'solution_mnt_checklist_details/' + checklist_id + '/';
        return this.http.delete<any>(url);
    }

    deleteSolutionHealthChecklist(checklist_id): Observable<any> {
        const url = this.baseUrl + 'solution_health_checklist_details/' + checklist_id + '/';
        return this.http.delete<any>(url);
    }

    deleteInstallationMntChecklist(checklist_id): Observable<any> {
        const url = this.baseUrl + 'installation_mnt_checklist/?checklist_id=' + checklist_id;
        return this.http.delete<any>(url);
    }

    deleteInstallationHealthChecklist(checklist_id): Observable<any> {
        const url = this.baseUrl + 'installation_health_checklist/?checklist_id=' + checklist_id;
        return this.http.delete<any>(url);
    }

    //checklist entry apis...
    getHealthChecklistEntryDetails(checklist_id, org_id): Observable<any> {
        const url = `${this.baseUrl}supplier/health_checklist_entry/`;
        let _params = new HttpParams().set('checklist_id', checklist_id).set('org_id', org_id);
        return this.http.get<any>(url, { params: _params });
    }

    updateHealthChecklistEntry(entry): Observable<any> {
        const url = `${this.baseUrl}supplier/health_checklist_entry/`;
        return this.http.put<any>(url, entry);
    }

    updateHealthChecklistFieldEntryFile(file): Observable<any> {
        const url = `${this.baseUrl}supplier/health_checklist_field_entry_file/`;
        return this.http.put<any>(url, file);
    }

    deleteHealthChecklistFieldEntryFile(file_id): Observable<any> {
        const url = `${this.baseUrl}supplier/health_checklist_field_entry_file/?file_id=${file_id}`;
        return this.http.delete<any>(url);
    }

    addEquipmentHealth(equipment_health) {
        const url = `${this.baseUrl}end_user/equipment_health_rating/`;
        return this.http.post(url, equipment_health);
    }

    //mnt apis

    getMaintenanceChecklistEntryDetails(checklist_id, org_id): Observable<any> {
        const url = `${this.baseUrl}end_user/maintenance_checklist_entry/`;
        let _params = new HttpParams().set('checklist_id', checklist_id).set('org_id', org_id);
        return this.http.get<any>(url, { params: _params });
    }

    updateMaintenanceChecklistEntry(entry): Observable<any> {
        const url = `${this.baseUrl}end_user/maintenance_checklist_entry/`;
        return this.http.put<any>(url, entry);
    }

    updateMaintenanceChecklistFieldEntryFile(file): Observable<any> {
        const url = `${this.baseUrl}end_user/checklist_field_entry_file/`;
        return this.http.put<any>(url, file);
    }

    deleteMaintenanceChecklistFieldEntryFile(file_id): Observable<any> {
        const url = `${this.baseUrl}end_user/checklist_field_entry_file/?file_id=${file_id}`;
        return this.http.delete<any>(url);
    }

    //checklist report apis

    getMaintenanceRecordInfo(sort_by, search_text, page_no, checklist_type, date, month, year): Observable<any> {
        const url = `${this.baseUrl}end_user/maintenance_record_info/`;
        let _params = new HttpParams()
            .set('ordering', sort_by)
            .set('search', search_text)
            .set('page', page_no)
            .set('checklist_type', checklist_type)
            .set('date', date)
            .set('month', month)
            .set('year', year);
        return this.http.get<any>(url, { params: _params });
    }

    getMaintenanceRecordInfoById(checklist_entry_id, checklist_category): Observable<any> {
        const url = `${this.baseUrl}end_user/maintenance_record_info/`;
        let _params = new HttpParams()
            .set('checklist_entry_id', checklist_entry_id)
            .set('checklist_category', checklist_category);
        return this.http.get<any>(url, { params: _params });
    }

    getMaintenanceReportFilterSDataSource(query) {
        const url = `${this.baseUrl}end_user/maintenance_record_filter_data/`;
        let _params = new HttpParams().set('query', query);
        return this.http.get<any>(url, { params: _params });
    }

    //spare apis
    getSpareParts(sort_by, search_text, part_number, part_name, vendor, page_no, spare_part_org = '', active_status = ''): Observable<any> {
        const url = this.baseUrl + 'spare_part_list/';
        const _params = new HttpParams()
            .set('ordering', sort_by)
            .set('search', search_text)
            .set('part_number', part_number)
            .set('part_name', part_name)
            .set('vendor__name', vendor)
            .set('page', page_no)
            .set('oem_organization', spare_part_org)
            .set('active_status', active_status);
        return this.http.get<any>(url, { params: _params });
    }

    getOEMVendors(page_no): Observable<any> {
        const url = this.baseUrl + `oem_vendors/?page=${page_no}`;
        return this.http.get<any>(url);
    }

    uniqueSparePartVerification(part_number, vendor, vendor_name): Observable<any> {
        const url = this.baseUrl + 'supplier/unique_verification_spare_parts/';
        const _params = new HttpParams()
            .set('part_number', part_number)
            .set('vendor', vendor)
            .set('vendor_name', vendor_name)
        return this.http.get<any>(url, { params: _params });
    }

    addSparePart(spare_part): Observable<any> {
        const url = this.baseUrl + 'spare_part_list/';
        return this.http.post<any>(url, spare_part);
    }

    updateEquipmentSpareParts(equipment): Observable<any> {
        const url = this.baseUrl + 'supplier/equipment_spare_part/';
        return this.http.put<any>(url, equipment);
    }

    //installed equipment

    addPrimaryInfo(info, id): Observable<any> {
        const url = this.baseUrl + `supplier/installed_equipment_details/${id}/`;
        return this.http.put(url, info);
    }

    //doc apis

    getDocumentLabels(): Observable<any> {
        const url = `${this.baseUrl}document_labels_list/?all=true`;
        return this.http.get<any>(url);
    }

    //my expert

    chatMyEquipmentExpert(query_obj): Observable<any> {
        const url = this.baseUrl + 'end_user/my_equipment_expert/';
        return this.http.post<any>(url, query_obj);
    }

    //get oem orgs list

    getOrganizationsDropList(user_type, all): Observable<any> {
        const url = this.baseUrl + 'org_dropdown_list/';
        const _params = new HttpParams()
            .set('user_type', user_type)
            .set('all', all);
        return this.http.get<any>(url, { params: _params });
    }

    //assign

    assignOEMInstallation(assign_details): Observable<any> {
        const url = `${this.baseUrl}end_user/assign_installed_equipment/`;
        return this.http.post(url, assign_details);
    }

    //installation

    deleteInstallation(installation_id): Observable<any> {
        const url = this.baseUrl + 'installation_details/' + installation_id + '/';
        return this.http.delete<any>(url);
    }

    //resellers

    getOEMResellers(oem_org_id = null, _function, solution, country_of_installation) {
        if (oem_org_id == null) oem_org_id = '';
        const url = `${this.baseUrl}oem_reseller_list/`;
        let _params = new HttpParams()
            .set('oem_org', oem_org_id)
            .set('function', _function)
            .set('solution', solution)
            .set('country_of_installation', country_of_installation);
        return this.http.get<any>(url, { params: _params });
    }

    //fm locations

    getFMOrgLocations(fm_org_id, fm_org_request_id, org_id): Observable<any> {
        let _params = new HttpParams()
            .set('fm_org_id', fm_org_id)
            .set('org_id', org_id)
            .set('fm_org_request_id', fm_org_request_id);
        const url = `${this.baseUrl}account/fm_org_locations_list/`;
        return this.http.get<any>(url, { params: _params });
    }

    //attributes update api
    updateEquipmentAttributeDetail(attribute): Observable<any> {
        const url = this.baseUrl + `end_user/installed-equipment-attributes/${attribute.id}/`;
        return this.http.put(url, attribute)
    }

}