import { Component, HostListener, Inject } from '@angular/core';
import { AlertService } from "./service/alert.service";
import { UtilityHelper } from './_helpers/utility.helper';
import { APP_CONFIG, AppConfig } from './app.config';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { UserService } from './service/user.service';
import { HttpClient } from '@angular/common/http';
import { Business } from './service/Business.service';
import { HelperService } from './service/helper.service';
import { AuthenticationService } from './service/authentication.service';
import { UrlService } from './service/url.service';

declare let gtag: Function;
declare let analytics: any;
declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  message: any = '';
  ewTitle = '';
  pages = [];
  show_footer = true;
  show_help_button = false;
  show_help_form = false;
  help_form = { message: '', attachment: '', page_url: '' };
  help_form_error = { message: false, attachment: false };
  upload_mode = true;
  uploaded_files = [];
  uploaded_files_error = [];
  submitting = false;

  @HostListener('window:focus', ['$event'])
  onWindowFocus(event: any) {
    if (this.router.url.includes('admin')) return;
    let page_name = this.getPageName(event.urlAfterRedirects);
    const page_data = {
      web_page_url: this.router.url, user: '', time_stamp: new Date(),
      win_active_time_stamp: new Date(), win_inactive_time_stamp: null, page_name: page_name
    };
    this.userService.logUserActivity(page_data).subscribe((resp) => {
    });
  }

  @HostListener('window:blur', ['$event'])
  onWindowBlur(event: any) {
    if (this.router.url.includes('admin')) return;
    let page_name = this.getPageName(event.urlAfterRedirects);
    const page_data = {
      web_page_url: this.router.url, user: '', time_stamp: new Date(),
      win_active_time_stamp: null, win_inactive_time_stamp: new Date(), page_name: page_name
    };
    this.userService.logUserActivity(page_data).subscribe((resp) => {
    });
  }

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private alertService: AlertService,
    private utilityHelper: UtilityHelper,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private http: HttpClient,
    private business: Business,
    public helper: HelperService,
    public authService: AuthenticationService,
    public urlService: UrlService
  ) {
    this.authService.currentUser.subscribe((user) => {
      if (!user) {
        this.show_help_button = false;
      }
    });
    this.http.get<any>('/assets/data/pages.json').subscribe((data) => {
      this.pages = data;
    });
    this.ewTitle = config.title;
    this.router.events.subscribe(event => {
      setTimeout(() => {
        this.setHelpButton();
      }, 1000);
      if (event instanceof NavigationEnd) {
        this.urlService.setCurrentPreviousUrl(event.url);
        if (event.urlAfterRedirects.includes('admin')) return;
        const page_name = this.getPageName(event.urlAfterRedirects);
        const page_data = {
          web_page_url: event.urlAfterRedirects, user: '', time_stamp: new Date(),
          win_active_time_stamp: null, win_inactive_time_stamp: null, page_name: page_name
        };
        // this.userService.logUserActivity(page_data).subscribe((resp) => {

        // });

        // gtag('config', 'G-NM71W5NWMZ', {'page_path': event.urlAfterRedirects});

      }
    });
  }

  ngOnInit() {
    this.business.getFooterSubject().subscribe((status) => {
      this.show_footer = status;
    })
  }

  setHelpButton() {
    const current_user = JSON.parse(sessionStorage.getItem('currentUser'));
    if (current_user) {
      if (['OEM', 'Food Manufacturer', 'Individual'].some(user_type => current_user?.user_type == user_type)) {
        this.show_help_button = true;
      }
    }
  }

  getPageName(url: string) {
    let page_name = url;
    if (this.pages.length === 0) return url;
    const page = this.pages.find(p => p.routes.some(r => url?.toLowerCase().includes(r?.toLowerCase())));
    if (page && page.name) {
      page_name = page.name;
    }
    // for (let p of this.pages) {
    //   let is_include = p.routes.some(x => url?.toLowerCase().includes(x?.toLowerCase()));
    //   if (is_include) {
    //     page_name = p.name;
    //     break;
    //   }
    // }
    return page_name;
  }

  onHelp() {
    this.help_form.page_url = this.router.url;
    this.show_help_form = true;
  }

  onCancelHelp() {
    this.help_form = { message: '', attachment: '', page_url: '' };
    this.uploaded_files = [];
    this.upload_mode = true;
    this.show_help_form = false;
  }

  onDocumentChange(files) {
    this.upload_mode = false;
    this.uploaded_files_error = [];
    this.uploaded_files = files;
    let file = files[0];
    const err_obj = { name: file.name, size_limit: false, type: false };
    if (file.size > (1024 * 1024 * 100)) {
      err_obj.size_limit = true;
      this.uploaded_files_error.push(err_obj);
    }
    const file_ext = file.name.split('.').pop();
    if (!this.helper.isImageFile(file.type, file_ext)) {
      err_obj.type = true
      this.uploaded_files_error.push(err_obj);
    }
    this.helper.getBase64FromFile(file).then((data) => {
      this.help_form.attachment = data;
    });
  }

  getFileSize(size) {
    return Math.ceil(size / 1024) + 'kb';
  }

  onRemoveUploadedFile(file, event: Event) {
    this.uploaded_files = [];
    this.help_form.attachment = '';
    if (this.uploaded_files.length === 0) {
      this.upload_mode = true;
    }
  }

  onSubmit() {
    this.help_form_error = { message: false, attachment: false };
    if (!this.help_form.message) {
      this.help_form_error.message = true;
      return;
    }
    if (this.uploaded_files_error.length > 0) {
      return;
    }
    this.submitting = true;
    this.userService.submitHelpMessage(this.help_form).subscribe(() => {
      this.submitting = false;
      this.alertService.success('Thank you for reaching out. We’ll get  in touch with you as soon as possible.', true);
      this.onCancelHelp();
    }, () => {
      this.submitting = false;
      this.alertService.error('Failed to submit');
    });
  }

}
