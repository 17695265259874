import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/service/alert.service';
import { HelperService } from 'src/app/service/helper.service';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { first } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { DocumentService } from 'src/app/service/document.service';
import { CurrentUserInfo, Feature, Permission, UserType } from 'src/app/model/user';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { InstalledEquipmentService } from 'src/app/service/installed-equipment.service';

@Component({
  selector: 'app-fill-checklist-form',
  templateUrl: './fill-checklist-form.component.html',
  styleUrls: ['./fill-checklist-form.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class FillChecklistFormComponent implements OnInit, OnDestroy {
  isView = true;
  type = '';
  checklist_id = null;
  solution_id = null;
  installed_equipment_id = null;
  equipment_health_rating = false;
  equipment_health = { health_rating: null, health_remarks: '', ferror: { rating: false, remarks: false } };
  oem_org_id = '';
  fm_org_id = '';
  // maintainence_checklist;
  checklist_entry;
  checklist_types = [{ id: 1, name: 'Daily' }, { id: 2, name: 'Weekly' }, { id: 3, name: 'Monthly' }, { id: 4, name: 'Quarterly' },
  { id: 5, name: 'Half-Yearly' }, { id: 6, name: 'Annual' }];
  save_on_destroy = true;
  complete_in_progress = false;
  show_changes_saved_msg = false;
  show_file_upload = true;
  current_field_no = 1;
  equipment_name: any;

  items: MenuItem[];
  activeIndex: number = 1;

  listdata: [];
  upload_mode = true;
  uploaded_files_error = [];
  uploaded_files = [];

  activeIndexs: number = 0;
  finish_box: boolean = false;
  task_box: boolean = false;
  reference_doc: any = [];
  max_input_value_error = false;
  can_fill = false;
  dynamic_data_perm: Permission = null;
  current_user: CurrentUserInfo;
  userType = UserType;

  updateEntry$: any;
  getChecklistDetails$: any;
  deleteChecklistFieldEntryFile$: any;
  updateChecklistFieldEntryFile$: any;

  constructor(
    private installedEquipmentService: InstalledEquipmentService,
    private route: ActivatedRoute,
    private _location: Location,
    private alertService: AlertService,
    public helper: HelperService,
    private confirmDialog: ConfirmDialogService,
    private router: Router,
    private documentService: DocumentService,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.initPermissions();
    this.route.queryParams.subscribe((params) => {
      this.checklist_id = params['checklist_id'];
      this.type = params['type'];
      this.oem_org_id = params['oem_org_id'];
      this.fm_org_id = params['fm_org_id'];
      this.equipment_name = params['equipment_name'];
      this.solution_id = params['solution_id'];
      this.installed_equipment_id = params['installed_equipment_id'];
      this.equipment_health_rating = params['equipment_health_rating'];
      this.setChecklistApiMethods();
    });
  }

  async initPermissions() {
    this.current_user = JSON.parse(sessionStorage.getItem('currentUser'));
    let permissions = await this.authService.getUserPermissions();
    this.dynamic_data_perm = permissions.find(x => x.feature_name == Feature.DynamicDataChecklistSpareList);
    let fm_mnt_checklist_perm: Permission = permissions.find(x => x.feature_name == Feature.ChecklistFillingRatingSupportRequest);
    this.can_fill = (this.current_user.user_type === this.userType.OEM && this.type === 'install_health' && this.dynamic_data_perm.fill) ||
      (this.current_user.user_type === this.userType.FoodManufacturer && this.type === 'install_mnt' && fm_mnt_checklist_perm.fill);
  }

  setChecklistApiMethods() {
    if (this.type == 'mnt') {
      this.getChecklistDetails$ = this.installedEquipmentService.getSolutionMntChecklistDetails(this.checklist_id);
      this.save_on_destroy = false;
      this.getChecklistDetails$.subscribe((checklist) => this.createEmptyForm(checklist));
    }
    if (this.type == 'health') {
      this.getChecklistDetails$ = this.installedEquipmentService.getSolutionHealthChecklistDetails(this.checklist_id);
      this.save_on_destroy = false;
      this.getChecklistDetails$.subscribe((checklist) => this.createEmptyForm(checklist));
    }
    if (this.type == 'install_mnt') {
      this.getChecklistDetails$ = this.installedEquipmentService.getMaintenanceChecklistEntryDetails(this.checklist_id, this.fm_org_id);
      if (this.current_user.user_type === this.userType.FoodManufacturer) {
        this.checklist_id && this.getChecklistEntryDetails();
        this.updateEntry$ = (obj: any) => this.installedEquipmentService.updateMaintenanceChecklistEntry(obj);
        this.updateChecklistFieldEntryFile$ = (file) => this.installedEquipmentService.updateMaintenanceChecklistFieldEntryFile(file);
        this.deleteChecklistFieldEntryFile$ = (file_id) => this.installedEquipmentService.deleteMaintenanceChecklistFieldEntryFile(file_id);
      } else {
        this.save_on_destroy = false;
        this.getChecklistDetails$.subscribe((checklist) => this.createEmptyForm(checklist));
      }
    }
    if (this.type == 'install_health' && this.current_user.user_type !== this.userType.FoodManufacturer) {
      this.getChecklistDetails$ = this.installedEquipmentService.getHealthChecklistEntryDetails(this.checklist_id, this.oem_org_id);
      this.getChecklistEntryDetails();
      this.updateEntry$ = (obj: any) => this.installedEquipmentService.updateHealthChecklistEntry(obj);
      this.updateChecklistFieldEntryFile$ = (file) => this.installedEquipmentService.updateHealthChecklistFieldEntryFile(file);
      this.deleteChecklistFieldEntryFile$ = (file_id) => this.installedEquipmentService.deleteHealthChecklistFieldEntryFile(file_id);
    }
  }

  ngOnDestroy(): void {
    if (this.save_on_destroy && !this.equipment_health_rating) {
      this.autoSave();
    }
  }

  onEditChecklist() {
    this.router.navigate(['/checklist-form'],
      {
        queryParams: {
          checklist_id: this.checklist_id,
          type: this.type,
          mode: 'edit',
          solution_id: this.solution_id,
          installed_equipment_id: this.installed_equipment_id
        }
      });
  }

  onFillChecklist() {
    this.isView = false;
  }

  getChecklistEntryDetails() {
    this.getChecklistDetails$.subscribe((data) => {
      this.checklist_entry = {
        id: data.id, equipment_checklist: data.equipment_checklist, equipment_rating: data.equipment_rating,
        checklist_description: data.checklist_description, checklist_type: data.checklist_type, checklist_name: data?.checklist_name ? data?.checklist_name : data?.name,
        checklist_type_name: data.checklist_type_name,
        remarks: data.remarks, completed: false,
        field_entries: [],
        ferror: { equipment_rating: false, remarks: false }
      };
      data.field_entries.forEach(x => {
        let _options = x.list_options.split(',');
        x.input_type == 3
        x.list_options = [..._options];

        let obj_options = [];
        _options.forEach(element => {
          obj_options.push({ "type": element })
        });

        const field_obj = {
          id: x.id, reference_files: x.reference_files, input_value: x.input_value, fm_notes: x.fm_notes, fm_file_urls: x.fm_file_urls, complete_status: x.complete_status,
          oem_instructions: x.oem_instructions, oem_file_url: x.oem_file_url, field_name: x.field_name,
          input_type: x.input_type, field_description: x.field_description, uom: x.uom, list_options: obj_options,
          ferror: { input_value: false, complete_status: false }
        }
        this.checklist_entry.field_entries.push(field_obj);
      });
      this.stepperChangePage();
    });
  }

  stepperChangePage() {

    let field_status = this.checklist_entry.field_entries.every(element => element.complete_status == true);

    if (field_status == true) {
      this.finish_box = true;
      this.task_box = false;
    } else {


      const index = this.checklist_entry.field_entries.findIndex(item => item.complete_status == false);
      this.activeIndexs = index;
      this.current_field_no = index + 1;


      /* this.checklist_entry.field_entries.forEach(x => {

        if (x.complete_status == false) {

          if (this.current_field_no == 1) {
            this.activeIndexs = 0
          } else if (this.current_field_no + 1 <= this.checklist_entry.field_entries.length) {
            this.current_field_no++;
            this.activeIndexs += 1
          } else if (this.current_field_no != 1) {
            this.current_field_no--;
            this.activeIndexs -= 1
          }
        }
      }) */
      this.task_box = true;
      this.finish_box = false;
    }
  }

  createEmptyForm(checklist) {
    this.checklist_entry = {
      id: null, equipment_checklist: checklist?.id, equipment_rating: null,
      checklist_description: checklist?.checklist_description, checklist_type: checklist?.checklist_type,
      checklist_name: checklist?.checklist_name ? checklist?.checklist_name : checklist?.name,
      checklist_type_name: checklist?.checklist_type_name,
      remarks: '', completed: false,
      field_entries: [],
      ferror: { equipment_rating: false, remarks: false }
    };
    const fields_prop_name = checklist.hasOwnProperty('checklist_fields') ? 'checklist_fields' : 'field_entries';
    // should it create from checklist_fields for empty form?
    checklist[fields_prop_name].forEach(x => {
      let _options = x.list_options.split(',');
      x.input_type == 3
      x.list_options = [..._options];

      let obj_options = [];
      _options.forEach(element => {
        obj_options.push({ "type": element })
      });

      const field_obj = {
        id: null, reference_files: x.reference_files, input_value: '', fm_notes: '', fm_file_urls: [], complete_status: false,
        oem_instructions: x.oem_instructions, oem_file_url: x.oem_file_url, field_name: x.field_name,
        input_type: x.input_type, field_description: x.field_description, uom: x.uom, list_options: obj_options,
        ferror: { input_value: false, complete_status: false }
      }
      this.checklist_entry.field_entries.push(field_obj);
    });
    this.stepperChangePage();
  }

  onCancel() {
    this.save_on_destroy = false;
    this._location.back();
  }

  removeFile(file, field) {
    this.confirmDialog.showConfirm('Confirm to delete file: ' + file.name).pipe(first()).subscribe((status) => {
      if (status) {
        this.deleteChecklistFieldEntryFile$(file.id).subscribe((resp) => {
          field.fm_file_urls = [...field.fm_file_urls.filter(x => x.id != file.id)];
        });
      }
    });
  }

  onFieldCompleteChange(field) {
    field.complete_status = !field.complete_status;
    const is_valid = this.validate_field(field);
    if (is_valid) {
      const obj = { ...this.checklist_entry };
      this.save(obj, false, true);
      this.stepperChangePage()

    } else if (field.complete_status) {
      field.complete_status = false;
    }
  }



  stepperChanged(event) {
    if (event + 1 <= this.checklist_entry.field_entries.length) {
      this.current_field_no = event + 1;
      this.activeIndexs = event;
    } else if (event + 1 != 1) {
      this.current_field_no = event - 1;
      this.activeIndexs = event;
    }
  }

  onFMFileUpload(file, field) {
    field.fm_files = [];
    const file_ext = file.name.split('.').pop();
    const file_name = file.name.split('.')[0]
    this.helper.getBase64FromFile(file).then((data) => {
      file = { field_entry_id: field.id, fm_file: data, fm_file_ext: file_ext, name: file_name.slice(0, 50) };
      this.updateChecklistFieldEntryFile$(file).subscribe((file) => {
        field.fm_file_urls.push(file);
        this.show_file_upload = false;
        setTimeout(() => {
          this.show_file_upload = true;
        }, 100);
      });
    });
  }

  checkLength(input_value) {
    this.max_input_value_error = false;
    if (input_value.length > 100) {
      this.max_input_value_error = true;
      this.alertService.error('Input value exceeds max length 100 characters');
    }
  }

  autoSave(input_value = null) {
    const obj = { ...this.checklist_entry };
    if (!input_value || (input_value && input_value?.className.includes('ng-dirty'))) {
      this.save(obj, false);
    }
  }

  save(obj, complete, field_done = false) {
    if (this.max_input_value_error) {
      this.alertService.error('Input value exceeds max length 50 characters');
      return;
    }

    this.updateEntry$(obj).subscribe((resp) => {
      if (complete) {
        this.save_on_destroy = false;
        this.alertService.success('checklist completed successfully', true);
        this._location.back();
        return;
      }
      if (field_done) {
        this.alertService.success('changes saved successfully');
      }
    }, (err) => {
      this.complete_in_progress = false;
    });
  }

  validate_field(field): boolean {
    field.ferror = { input_value: false, complete_status: false };
    if (!field.input_value) {
      field.ferror.input_value = true;
    }

    const eKeys = Object.keys(field.ferror);
    return eKeys.every((ek) => field.ferror[ek] == false);
  }

  validate(): boolean {
    this.checklist_entry.ferror = { equipment_rating: false, remarks: false };
    this.checklist_entry.field_entries.forEach(x => {
      x.ferror = { input_value: false, complete_status: false };
    });
    if (!this.checklist_entry.equipment_rating) {
      this.checklist_entry.ferror.equipment_rating = true;
    }
    if (!this.checklist_entry.remarks) {
      this.checklist_entry.ferror.remarks = true;
    }

    this.checklist_entry.field_entries.forEach(x => {
      if (!x.input_value) {
        x.ferror.input_value = true;
      } else {
        x.ferror.input_value = false;
      }

      if (!x.complete_status) {
        x.ferror.complete_status = true;
      } else {
        x.ferror.complete_status = false;
      }
    });

    let ff_errors = [].concat(...this.checklist_entry.field_entries.map(x => x.ferror));
    const fields_is_valid = ff_errors.every(x => {
      return Object.keys(x).every(fkey => x[fkey] == false);
    });

    const eKeys = Object.keys(this.checklist_entry.ferror);
    const isValid = eKeys.every((ek) => this.checklist_entry.ferror[ek] == false);
    return isValid && fields_is_valid;
  }

  onComplete() {
    this.complete_in_progress = true;
    const is_valid = this.validate();
    if (is_valid) {
      const obj = { ...this.checklist_entry };
      obj.completed = true;
      this.save(obj, true);
    } else {
      this.alertService.error('please fill all required fields');
      this.complete_in_progress = false;
    }
  }

  // onRequestForSupport() {
  //   this.oemService.maintenanceRequestForSupport(this.checklist_id).subscribe((resp) => {
  //     this.alertService.success(resp?.message, true);
  //     this.save_on_destroy = false;
  //     this._location.back();
  //   });
  // }

  onNextField(direction) {
    if (direction == 'right') {
      this.activeIndexs += 1;
      if (this.current_field_no + 1 <= this.checklist_entry.field_entries.length) {
        this.current_field_no++;
      }
    }
    if (direction == 'left') {
      if (this.current_field_no != 1) {
        this.current_field_no--;
      }
    }
  }

  //documents:
  onDocumentChange(files) {
    this.upload_mode = false;
    this.uploaded_files_error = [];
    this.uploaded_files = Array.from(files);
    // this.uploaded_files.forEach(x => x.size = Math.ceil(x.size / 1024));
    this.uploaded_files.forEach(f => {
      const err_obj = { name: f.name, size_limit: false, type: false };
      if (f.size > (1024 * 1024 * 100)) {
        err_obj.size_limit = true;
        this.uploaded_files_error.push(err_obj);
      }
      const file_ext = f.name.split('.').pop();
      if (!this.helper.isValidFile(f.type, file_ext)) {
        err_obj.type = true
        this.uploaded_files_error.push(err_obj);
      }
    });
  }

  onDocumentAdd(field) {

    if (this.uploaded_files.length === 0) {
      this.alertService.error('Upload the document to add');
      return;
    }
    if (this.uploaded_files_error.length > 0) {
      this.alertService.error('Some of the uploaded files are not valid or exceeds maximum size limit');
      return;
    }

    const file_data_promises = this.uploaded_files.map(f => this.helper.getBase64FromFile(f));

    Promise.all(file_data_promises).then(datas => {
      datas.forEach((data, i) => {

        let new_doc = {
          field_entry_id: field.id,
          fm_file: data,
          fm_file_ext: this.uploaded_files[i].name.split('.').pop(),
          name: this.uploaded_files[i].name.split('.')[0],
          file_size: this.uploaded_files[i].size
        };

        this.updateChecklistFieldEntryFile$(new_doc).subscribe((file) => {
          field.fm_file_urls.push(file);

          setTimeout(() => {
            this.checklist_entry.field_entries;
          }, 100);
        });
      });
      this.uploaded_files = [];
      (document.getElementById('sol-doc-input') as any).value = null;
    });
    this.upload_mode = true;
  }

  getFileSize(size) {
    return Math.ceil(size / 1024) + 'kb';
  }

  onRemoveUploadedFile(file, event: Event) {
    this.uploaded_files = [...this.uploaded_files.filter(x => x.name != file.name)];
    if (this.uploaded_files.length === 0) {
      this.upload_mode = true;
    }
  }

  downloadDocument(doc) {
    /* this.oemService.downloadDocument(doc.document_id).subscribe((resp) => {

    }, (err) => {

    }); */
  }

  logDownload(doc_id) {
    // this.installedEquipmentService.logDownloadFile(doc_id).subscribe((resp) => { });
  }

  onDocumentClick(doc) {
    // let url = this.router.createUrlTree(['/document-view'], { queryParams: { blob_name: doc?.blob_name || doc?.fm_file_blob } });
    // window.open(this.router.serializeUrl(url), '_blank');
    this.documentService.openDocumentInPage(doc?.blob_name, doc.file_name);
  }

  setMntChecklistEquipmentRating(rating) {
    this.checklist_entry.equipment_rating = rating;
  }

  setEquipmentHealthRating(rating) {
    this.equipment_health.health_rating = rating;
  }

  onSaveEquipmentRating() {
    this.equipment_health.ferror = { rating: false, remarks: false };
    if (!this.equipment_health.health_remarks) {
      this.equipment_health.ferror.remarks = true;
    }
    if (!this.equipment_health.health_rating) {
      this.equipment_health.ferror.rating = true;
    }
    if (JSON.stringify(this.equipment_health.ferror).includes('true')) {
      return;
    }
    const obj = {
      installed_equipment: this.installed_equipment_id,
      health_rating: this.equipment_health.health_rating,
      health_remarks: this.equipment_health.health_remarks
    };
    this.installedEquipmentService.addEquipmentHealth(obj).subscribe((resp) => {
      this.alertService.success('Equipment health saved successfully', true);
      this._location.back();
    });
  }
}
